import { Button, Spin, Tabs, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOneData from '../../../cutom-hooks/useGetOneData';
import PageHeader from '../../../components/HeaderPage';
import ExamStudents from '../components/ExamStudent';
import useUrlQueryParams from '../../../cutom-hooks/useUrlQueryParams';

interface DataType {
  name: string;
  value: ReactNode;
  value2?: ReactNode;
  value3?: ReactNode;
}

const sharedOnCell = (_: DataType, index: number | undefined) => {
  if (index || index == 0) {
    if (index < 2) {
      return { colSpan: 0 };
    }
  }
  return {};
};

const dateParserToDatePicker = (second: number | undefined) => dayjs((new Date(Number(second) * 1000))).format('DD-MM-YYYY HH:mm')

const ExamView: React.FC = (): JSX.Element => {

  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate();
  const {urlValue, writeToUrl } = useUrlQueryParams({});

  const { data, isLoading } = useGetOneData<any>({
    queryKey: ['exams', id],
    url: `exams/${id}?expand=eduYear,direction.directionSubject.subject`,
    options: {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!id
    },
  });

  const columnsviewTable: ColumnsType<DataType> = [
    {
      title: t("Name"),
      dataIndex: "name",
      rowScope: "row",
    },
    {
      title: t("Value"),
      dataIndex: "value",
      onCell: (_, index) => ({
        colSpan: (index == 4 || index == 3 || index == 5 || index == 2) ? 1 : 3,
      }),
    },
    {
      title: t("Name2"),
      dataIndex: "value2",
      onCell: (_, index) => sharedOnCell(_, index),
      className: "bg-[#FAFAFA]"
    },
    {
      title: t("Name3"),
      dataIndex: "value3",
      onCell: (_, index) => sharedOnCell(_, index),
    },
  ];

  const tableData: DataType[] = [
    {
      name: t("Direction"),
      value: data?.data?.direction?.name
    },
    {
      name: t("Ball"),
      value: data?.data?.direction?.directionSubject?.map((e: any, i: number) => <p><b className='opacity-60' >{e?.subject?.name}: </b> {e?.ball} balldan {e?.question_count} ta jami <b>{e?.max_ball}</b> ball </p>),
    },
    {
      name: t("Duration"),
      value: <>{data?.data?.duration_time} (minut)</>,
      value2: t("Edu Year"),
      value3: data?.data?.eduYear?.name,
    },
    {
      name: t("Start time"),
      value: dateParserToDatePicker(data?.data?.start_time),
      value2: t("End time"),
      value3: dateParserToDatePicker(data?.data?.start_time),
    },
  ];

  const headerTitle = data?.data?.direction ? ((data?.data?.direction?.name + " (" + data?.data?.eduYear?.name + ")") ?? "") : "Yakuniy imtihonni ko'rish"
  return (
    <Spin spinning={isLoading}>
      <PageHeader
        breadCrumbData={[
          { name: "Asosiy sahifa", path: "/" },
          { name: "Imtihonlar", path: "/exams" },
          { name: headerTitle, path: "" },
        ]}
        isBack={true}
        title={headerTitle}
        btn={<div>
          {<Button onClick={() => navigate(`/exams/update/${id}`)} >{t("Update")}</Button>}
        </div>}
      />

      <Tabs
        defaultActiveKey="info"
        activeKey='student'
        onChange={(e) => { writeToUrl({name: "tab", value: e}) }}
        items={[
          {
            key: 'info',
            label: 'Imtihon ma\'lumotlari',
            children: <div className='py-3'>
              <Table
                columns={columnsviewTable}
                bordered
                dataSource={tableData}
                showHeader={false}
                pagination={false}
                className='mb-4'
              />

              {/* <ExamViewTab examView={data?.data} /> */}
            </div>,
          },
          {
            key: 'student',
            label: 'Imtihonda qatnashgan talabalar',
            children: <ExamStudents />,
          },
        ]} />

    </Spin>
  )
}

export default ExamView;