import { Button, Col, Divider, Form, InputNumber, message, Modal, Row, Select, Spin, Switch } from "antd";
import { useParams } from "react-router-dom";
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CLIENT_API } from "../../../services/client.request";
import { submitSubject } from "./request";
import { AxiosError } from "axios";
import { Notification } from "../../../components/Notifications/notification";

type TypeAddSubject = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
    subjectInfo: any,
    setSubjectInfo: Dispatch<SetStateAction<any>>
    refetch: () => void
}

const AddDiractionSubject: FC<TypeAddSubject> = ({ open, setOpen, subjectInfo, setSubjectInfo, refetch }) => {
    const [form] = Form.useForm();
    const { direction_id } = useParams();
    const [levelError, setLevelError] = useState<boolean>(false)
    const _1: any = useRef(null);
    const _2: any = useRef(null);
    const _3: any = useRef(null);

    const { data, isError, isFetching } = useQuery<any>({
        queryKey: ["direction-subjects", subjectInfo?.id],
        queryFn: () => CLIENT_API.getOne({ url: `/direction-subjects/${subjectInfo?.id}` }),
        enabled: !!subjectInfo?.id,
        refetchOnWindowFocus: false,
        retry: 0,
    });

    const { data: subjects, isFetching: isLoadingSubject } = useQuery<any>({
        queryKey: ["subjects"],
        queryFn: () => CLIENT_API.getOne({ url: `/subjects` }),
        refetchOnWindowFocus: false,
        retry: 0,
    });

    // useEffect(() => {
    //     if (data?.status == 1) {
    //         let resData = data?.data
    //         form.setFieldsValue({
    //             ball: resData?.ball,
    //             question_count: resData?.question_count,
    //             subject_id: resData?.subject_id,
    //             type: resData?.type || undefined,
    //             is_certificate: resData?.is_certificate ? true : false,
    //         });

    //         const level = resData?.question_distribution
    //         if (_1.current && level["1"]) _1.current.value = level["1"];
    //         if (_2.current && level["2"]) _2.current.value = level["2"];
    //         if (_3.current && level["3"]) _3.current.value = level["3"];

    //     }
    // }, [data?.status])

    useEffect(() => {
        if (isError) message.error("Malumot yuklashda xatolik!")
    }, [isError]);

    useEffect(() => {
        if(subjectInfo){
            form.setFieldsValue(subjectInfo)
        }        
        form.setFieldsValue({})
    }, [open]);

    // const distributeBall = (type: 1 | 2 | 3, value: number | null) => {
    //     setLevelError(false);
    //     const level = { ...JSON.parse(form.getFieldValue("question_distribution") ?? "{}"), [type]: value };
    //     let a = 0;
    //     for (const i in level) {
    //         a += level[i];
    //     }

    //     if (form.getFieldValue("question_count") != a) setLevelError(true)
    //     form.setFieldValue("question_distribution", JSON.stringify(level));
    // }

    const { mutate, isPending: submitLoading } = useMutation({
        mutationFn: (values) => submitSubject(subjectInfo?.id, direction_id, values),
        onSuccess: async (res) => {
            console.log("ssssssssss");
            
            Notification("success", "update", res?.message);
            setSubjectInfo(undefined);
            refetch()
            setOpen(false);
            form.resetFields()
        },
        onError: (error: AxiosError<any>) => {
            Notification("error", "create", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    return (
        <Modal
            title={""}
            footer={null}
            open={open}
            onCancel={() => { setOpen(false); setSubjectInfo(undefined); form.resetFields() }}
            width={800}
            style={{ top: 16 }}
        >
            <Spin spinning={isFetching}>
                <div className="my-4">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={mutate}
                        autoComplete="off"
                        size='large'
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Subject"
                                    name="subject_id"
                                    rules={[{ required: true, message: 'Please input question count!' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a subject"
                                        optionFilterProp="children"
                                        options={subjects?.data?.items?.map((e: any, i: number) => ({ value: e?.id, label: e?.name }))}
                                        loading={isLoadingSubject}
                                        allowClear
                                        filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Blok"
                                    name="type"
                                    rules={[{ required: true, message: 'Please input question count!' }]}
                                >
                                    <Select
                                        placeholder="Select a block"
                                        optionFilterProp="children"
                                        options={[
                                            {value: 1, label: "1-fan"},
                                            {value: 2, label: "2-fan"},
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Savollar soni"
                                    name="question_count"
                                    rules={[{ required: true, message: 'Please input question count!' }]}
                                >
                                    <InputNumber className="w-full" min={0} placeholder="Ma'lumot kiriting" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={8}>
                                <Form.Item
                                    label="Ball (har bir savol uchun)"
                                    name="ball"
                                    rules={[{ required: true, message: 'Please input ball!' }]}
                                >
                                    <InputNumber className="w-full" min={0} placeholder="Ma'lumot kiriting" />
                                </Form.Item>
                            </Col> */}
                        </Row>
                        <Row gutter={[12, 8]} className="mb-4" >
                            {/* <Col span={24}><span>Savollar taqsimoti</span></Col>
                            <Col span={8}>
                                <InputNumber ref={_1} addonBefore="Oson" onChange={(e) => distributeBall(1, e)} className="w-full" min={0} placeholder="Ma'lumot kiriting" />
                            </Col>
                            <Col span={8}>
                                <InputNumber ref={_2} addonBefore="O'rta" onChange={(e) => distributeBall(2, e)} className="w-full" min={0} placeholder="Ma'lumot kiriting" />
                            </Col>
                            <Col span={8}>
                                <InputNumber ref={_3} addonBefore="Qiyin" onChange={(e) => distributeBall(3, e)} className="w-full" min={0} placeholder="Ma'lumot kiriting" />
                            </Col> */}
                            {levelError ? <Col span={24} >
                                <span className="text-red-600">Taqsimot ballar yig'indisi savollar soniga teng bo'lishi kerak</span>
                            </Col> : null}
                            {/* <Col span={24} >
                                <Form.Item
                                    name="question_distribution"
                                    className="m-0 p-0 h-0 hidden-"
                                >
                                </Form.Item>
                            </Col> */}
                        </Row>
                        {/* <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Sertifikat majburiyligi"
                                    name="is_certificate"
                                >
                                    <Switch checkedChildren="Active" unCheckedChildren="InActive" />
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Divider />
                        <div className="text-end">
                            <Button danger htmlType="reset" onClick={() => setOpen(false)} >Yopish</Button>
                            <Button loading={submitLoading} type="primary" htmlType="submit" className="ms-2" disabled={levelError} >Saqlash</Button>
                        </div>
                    </Form>
                </div>
            </Spin>
        </Modal>
    )
}
export default AddDiractionSubject;