import instance from "../../../config/axios.config";

export const submitSubject = async (id: number | undefined, direction_id: string | undefined, values: any) => {

    const formdata = new FormData();

    for (const key in values) {
        if(key === 'is_certificate'){
            formdata.append(key, values[key] ? "1" : "0");
        }else if(key === 'question_count'){
            formdata.append(key, values[key]);
            formdata.append("question_distribution", JSON.stringify({1: values[key]}));
        } else {
            formdata.append(key, values[key]);
        }
    }

    if (direction_id) formdata.append("direction_id", String(direction_id));

    const response = await instance({ url: !id ? '/direction-subjects' : `/direction-subjects/${id}`, method: id ? "PUT" : "POST", data: formdata });
    return response.data;
}