import { ThemeConfig } from "antd"

export const antdCustomTokens = ():ThemeConfig => {

    return {
        token: {
            colorPrimary: "#1c89c1",
            colorText: "#002248",
            colorTextBase: "rgba(0, 0, 0, 1)",
            colorBgBase: "#fff",
            // lightSiderBg: "#f5f5f5",
            // colorBgLayout: "white",
            // colorBgContainer: "white",
            // colorBgSpotlight: "white",
            // colorBgElevated: "red",
            // colorBgMask: "red"
        },
        components: {
            Select: {
                optionSelectedBg: 'rgba(28,137,193,.16)'
            },
            Button: {
                contentFontSize: 16
            },
            Input: {
                fontSize: 16,
                activeBorderColor: "#1c89c1",
                activeShadow: "#1c89c1"
            },
            Form: {
                labelFontSize: 16,
                fontSize: 17
            },
            Table: {
                fontSize: 18
            },
            Tabs: {
                fontSize: 18
            },
            Steps: {
                fontSize: 18,
            },
            Breadcrumb: {
                fontSize: 18
            },
            Menu: {
                fontSize: 16
            },
            Layout: {
                bodyBg: "#f6f8fb"
            },
        }
    }
}