import {Dispatch, useEffect, useState} from 'react'
import { Button, Drawer, Form, Input, Select } from "antd";
import { useMutation } from '@tanstack/react-query';
import { Notification } from '../../../components/Notifications/notification';
import { AxiosError } from 'axios';
import { submitData } from './request';
import MultipleInput from '../../../components/MultipleInput';
import useGetAllData from '../../../cutom-hooks/useGetAllData';
import { getLanguages } from '../../../utils/language';

const UpdateData = ({open, setOpen, refetch, selectedItem, setselectedItem}: {open: boolean, setOpen: Dispatch<boolean>, refetch: any, selectedItem: any, setselectedItem: Dispatch<any>}) => {

    const [form] = Form.useForm();
    const [languages, setLanguages] = useState<any>();

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        (
          async () => {
            const response = await getLanguages();
            setLanguages(response)
          }
        )()
      }, [])
    
    useEffect(() => {
        form.setFieldsValue({
            name: selectedItem?.name,
            edu_form_id: selectedItem?.edu_form_id,
            edu_duration: selectedItem?.edu_duration,
            language_id: selectedItem?.language_id,
            contract_price: selectedItem?.contract_price,
            direction_code: selectedItem?.direction_code,
        })
    }, [selectedItem])
 
    useEffect(() => {
        if(!open) setselectedItem(undefined) 
    }, [open])

    const { mutate, isPending } = useMutation({
        mutationFn: (newVals) => submitData(selectedItem?.id, newVals),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message)
          refetch()
          setselectedItem(undefined)
          setOpen(false)
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    const { data, isPending: selectLoading } = useGetAllData({
        queryKey: ["edu-forms"],
        url: "edu-forms",
        options: {
            enabled: !!open,
        }
    })  

    const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div>
            <Drawer key={3} title="Yo'nalish" placement="right" width={500} onClose={onClose} open={open}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={mutate}
                    autoComplete="off"
                    size='large'
                >
                    {
                        selectedItem?.id ? 
                        <Form.Item
                            label="Nomi"
                            name="name"
                            rules={[{required: true, message: 'Please input name!'}]}
                        >
                            <Input placeholder="Nomi"/>
                        </Form.Item>
                        :<MultipleInput layout='vertical' inputSpan={24} textAreaSpan={24} textAreaRows={3} isDescription={false} />
                    }

                    <Form.Item
                        label="Ta'lim shakli"
                        name="edu_form_id"
                        rules={[{required: true, message: 'Please select edu form!'}]}
                    >
                        <Select
                            loading={selectLoading}
                            placeholder="Ta'lim shaklini tanlang"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={data?.items?.length ? data?.items?.map((i: any) => ({label: i?.name, value: i?.id})) : []}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Ta'lim tili"
                        name="language_id"
                        rules={[{required: true, message: 'Please select language!'}]}
                    >
                        <Select
                            loading={selectLoading}
                            placeholder="Ta'lim tilini tanlang"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={languages?.items?.length ? languages?.items?.map((i: any) => ({label: i?.name, value: i?.id})) : []}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Yo'nalish kodi"
                        name="direction_code"
                        rules={[{required: true, message: "Yo'nalish kodini kiriting"}]}
                    >
                        <Input type='number' placeholder="Yo'nalish kodini kiriting" />
                    </Form.Item>

                    <Form.Item
                        label="Konrakt summasi (so'm)"
                        name="contract_price"
                        rules={[{required: true, message: 'Konrakt summasini kiriting!'}]}
                    >
                        <Input type='number' placeholder="Konrakt summasini kiriting" />
                    </Form.Item>

                    <Form.Item
                        label="O'qish davomiyligi (yil)"
                        name="edu_duration"
                        rules={[{required: true, message: "O'qish davomiyligini kiriting!"}]}
                    >
                        <Input type='number' placeholder="O'qish davomiyligi" />
                    </Form.Item>

                    <Form.Item className='flex justify-end'>
                        <Button type="primary" htmlType="submit" loading={isPending}>
                            Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}
export default UpdateData;