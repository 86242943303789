import { Avatar, Dropdown } from "antd"
import { BiUser } from "react-icons/bi"
import type { MenuProps } from 'antd';
import { logOut } from "../../pages/login/request";


const HeaderDropdown = () => {

    const items: MenuProps['items'] = [
        {
          label: <p className="m-0">Admin</p>,
          key: '0',
        },
        {
          label: <p className="m-0">Log out</p>,
          onClick: () => {
            console.log("dasdas");
            logOut()
          },
          key: '1',
          danger: true,
        },
      ];

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <Avatar className="cursor-pointer" size={40} style={{ backgroundColor: '#87d068' }} icon={<BiUser />} />
        </Dropdown>
    )
}
export default HeaderDropdown;