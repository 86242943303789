import { ReactNode, useRef} from 'react'
import { Alert, Button, Spin, Table } from "antd";
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useGetOneData from '../../../cutom-hooks/useGetOneData';
import PageHeader from '../../../components/HeaderPage';
import useWindowSize from '../../../cutom-hooks/useWindowSize';
import { FILE_URL } from '../../../config/utils';
import { examTypes } from '../../../static_dates/datas';
import ShartNomaQabulUzNew from '../../../components/sheetsNew/shartnoma_qabul_uz';
import ShartNomaQabulRuNew from '../../../components/sheetsNew/shartnoma_qabul_ru';
import ShartNomaQabulThreeSideRuNew from '../../../components/sheetsNew/shartnoma_qabul_three_side_ru';
// @ts-ignore
import ShartNomaQabulThreeSideUzNew from '../../../components/sheetsNew/shartnoma_qabul_three_side_uz';


interface DataType {
    name: string;
    value: ReactNode;
    name2?: ReactNode;
    value2?: ReactNode;
}
  
const columns: ColumnsType<DataType> = [
    {
      dataIndex: "name",
    },
    {
      dataIndex: "value",
    },
    {
      dataIndex: "name2",
      className: "bg-[#FAFAFA]"
    },
    {
      dataIndex: "value2",
    },
]

const columnsMobile: ColumnsType<DataType> = [
    {
      dataIndex: "name",
      className: "bg-[#FAFAFA]"
    },
    {
      dataIndex: "value",
    }
]


const StudentView = () => {

    const {t} = useTranslation();
    const { student_id } = useParams()
    const {width} = useWindowSize();
    const iframe_ref = useRef<HTMLIFrameElement | null>(null);
    const print_ref = useRef<any>(null);

    const iframe_ref_three_side = useRef<HTMLIFrameElement | null>(null);
    const print_ref_three_side = useRef<any>(null);

    const { data, isPending } = useGetOneData({
        queryKey: ["students", student_id],
        url: `students/${student_id}`,
        urlParams:{
            expand: "createdBy,updatedBy,eduForm,contract"
        }
    })

    function printContract({type}: {type: 2 | 3}) {

        let freme_current;
        let print_current;
        if(type === 2) {
            freme_current = iframe_ref?.current;
            print_current = print_ref.current;
        } else if(type === 3) {
            freme_current = iframe_ref_three_side?.current
            print_current = print_ref_three_side.current;
        }
  
        if (freme_current) {

            const iframe = freme_current;
            iframe.contentDocument?.open()
            if (print_current) {
                iframe.contentDocument?.write(print_current?.innerHTML)
            }

            const iframeDocument = freme_current.contentDocument;
            if (iframeDocument) {
                const styleElement = iframeDocument.createElement('style');
                styleElement.textContent = `
                    @media print {
                        @page { size: portrait; }
                        html{
                            padding-left: 50px;
                        }

                        *{
                            font-family: Times New Roman
                            TimesNewRomanPSMT
                            Times New Roman Bold
                            TimesNewRomanPS-BoldMT
                            Times New Roman Italic
                        }

                        p{
                            margin: 0px;
                            font-size: 15px;
                        }
                    }
                    
                `;
                iframeDocument.head.appendChild(styleElement);
            }

            iframe.contentDocument?.close();
            iframe.focus();
            iframe.contentWindow?.print()

        }

      }

    const tableData1: DataType[] = [
        {
            name: "Ism",
            value: data?.data?.first_name || '---',
            name2: "Familiya",
            value2: data?.data?.last_name || '---',
        },
        {
            name: "Otasining ismi",
            value: data?.data?.middle_name || '---',
            name2: "Tug'ilgan sana",
            value2: data?.data?.birthday || '---',
        },
        {
          name: "JSHIR",
          value: data?.data?.passport_pin || '---',
          name2: "Pasport seriyasi va raqami",
          value2: data?.data?.passport_serial + " " + data?.data?.passport_number || '---',
        },
        {
            name: "Pasport berilgan sana",
            value: data?.data?.passport_given_date || '---',
            name2: "Pasport amal qilish muddati",
            value2: data?.data?.passport_issued_date || '---',
        },
        {
            name: "Pasport kim tomonidan berilgan",
            value: data?.data?.passport_given_by || '---',
            name2: "Jins",
            value2: data?.data?.gender === 1 ? "Erkak" : "Ayol",
        },
    ];

    const tableData1Mobile: DataType[] = [
        {
            name: "Ism",
            value: data?.data?.first_name || '---',
        },
        {
            name: "Familiya",
            value: data?.data?.last_name || '---',
        },
        {
            name: "Otasining ismi",
            value: data?.data?.middle_name || '---',
        },
        {
            name: "Tug'ilgan sana",
            value: data?.data?.birthday || '---',
        },
        {
          name: "JSHIR",
          value: data?.data?.passport_pin || '---',
        },
        {
            name: "Pasport seriyasi va raqami",
            value: data?.data?.passport_serial + " " + data?.data?.passport_number || '---',
          },
        {
            name: "Pasport berilgan sana",
            value: data?.data?.passport_given_date || '---',
        },
        {
            name: "Pasport amal qilish muddati",
            value: data?.data?.passport_issued_date || '---',
        },
        {
            name: "Pasport kim tomonidan berilgan",
            value: data?.data?.passport_given_by || '---',
        },
        {
            name: "Jins",
            value: data?.data?.gender === 1 ? "Erkak" : "Ayol",
        },
    ];

    const tableData2: DataType[] = [
        {
            name: "Oxirgi ta'lim muassasasi",
            value: data?.data?.edu_type?.name,
            name2: "Muassasasi nomi",
            value2: data?.data?.edu_name || "---",
        },
        {
            name: "Diplom yoki shahodatnoma",
            value: data?.data?.diploma_type === 1 ? "Bor" : "Yo'q",
            name2: "Diplom yoki shahodatnoma fayli",
            value2: data?.data?.d_file ? <a href={FILE_URL + data?.data?.d_file} target="_blank">Faylni  ko'rish</a> : "---",
        },
        {
            name: "Chet tilidan sertifikat bormi",
            value: data?.data?.certificate_type === 1 ? "Bor" : "Yo'q",
            name2: "Chet tilidan sertifikat fayli",
            value2: data?.data?.c_file ? <a href={FILE_URL + data?.data?.c_file} target="_blank">Faylni  ko'rish</a> : "---",

        },
        {
            name: "Sertifikat turi",
            value: data?.data?.certificate_level ? data?.data?.certificate_level?.name : "Yo'q",
            name2: "Sertifikat darajasi",
            value2: data?.data?.certificate_level_type ? data?.data?.certificate_level?.level?.find((e: any) => e?.id == data?.data?.certificate_level_type)?.name : "---",
        },
    ];

    const tableData2Mobile: DataType[] = [
        {
            name: "Oxirgi ta'lim muassasasi",
            value: data?.data?.edu_type?.name,
        },
        {
            name: "Muassasasi nomi",
            value: data?.data?.edu_name || "---",
        },
        {
            name: "Diplom yoki shahodatnoma",
            value: data?.data?.diploma_type === 1 ? "Bor" : "Yo'q",
        },
        {
            name: "Diplom yoki shahodatnoma fayli",
            value: data?.data?.d_file ? <a href={FILE_URL + data?.data?.d_file} target="_blank">Faylni  ko'rish</a> : "---",
        },
        {
            name: "Chet tilidan sertifikat bormi",
            value: data?.data?.certificate_type === 1 ? "Bor" : "Yo'q",
        },
        {
            name: "Chet tilidan sertifikat fayli",
            value: data?.data?.c_file ? <a href={FILE_URL + data?.data?.c_file} target="_blank">Faylni  ko'rish</a> : "---",
        },
        {
            name: "Sertifikat turi",
            value: data?.data?.certificate_level ? data?.data?.certificate_level?.name : "Yo'q",
        },
        {
            name: "Sertifikat darajasi",
            value: data?.data?.certificate_level_type ? data?.data?.certificate_level?.level?.find((e: any) => e?.id == data?.data?.certificate_level_type)?.name : "---",
        },
    ];


    const tableData3: DataType[] = [
        {
            name: t("Which exam type do you want to enter Perfect University"),
            value: examTypes.find(i => i?.value == data?.data?.exam_type)?.label,
        },
        {
            name:  t("Answer Sheet File"),
            value: data?.data?.dtm_file ? <a href={FILE_URL + data?.data?.dtm_file} target="_blank">{t("Faylni ko'rish")}</a> : "---",
            name2: t("Exam Permit File"),
            value2: data?.data?.permit_file ? <a href={FILE_URL + data?.data?.permit_file} target="_blank">{t("Faylni ko'rish")}</a> : "---",
        }
    ];

    const tableData3Mobile: DataType[] = [
        {
            name: t("Which exam type do you want to enter Perfect University"),
            value: examTypes.find(i => i?.value == data?.data?.exam_type)?.label,
        },
        {
            name: t("Answer Sheet File"),
            value: data?.data?.dtm_file ? <a href={FILE_URL + data?.data?.dtm_file} target="_blank">{t("Faylni ko'rish")}</a> : "---",
        },
        {
            name: t("Exam Permit File"),
            value: data?.data?.permit_file ? <a href={FILE_URL + data?.data?.permit_file} target="_blank">{t("Faylni ko'rish")}</a> : "---",
        }
    ];



    const tableData4: DataType[] = [
        {
            name: "Ta'lim shakli",
            value: data?.data?.eduForm?.name,
            name2: "Ta'lim yo'nalishi",
            value2: data?.data?.direction?.name,
        },
        {
            name: "Ta'lim tili",
            value: data?.data?.language?.name,
            name2: "",
            value2: "",
        }
    ];

    const tableData4Mobile: DataType[] = [
        {
            name: "Ta'lim shakli",
            value: data?.data?.eduForm?.name,
        },
        {
            name: "Ta'lim yo'nalishi",
            value: data?.data?.direction?.name,
        },
        {
            name: "Ta'lim tili",
            value: data?.data?.language?.name,
        }
    ];


    const title = `${data?.data?.last_name} ${data?.data?.first_name} ${data?.data?.middle_name}`

    return (
        <div>
            <Spin spinning={isPending} >
                <div className="">
                    <PageHeader
                        breadCrumbData={[
                            { name: "Asosiy panel", path: '/' },
                            { name: "Talabalar", path: '/students' },
                            { name: title, path: '/students' }
                        ]}
                        title={title}
                        isBack={true}
                    />
                    <div className="main-card">


                    <div className="flex justify-between items-center my-4">
                        <h3>{t("Personal information")}</h3>
                    </div>
                    <Table
                        columns={width > 768 ? columns : columnsMobile}
                        bordered
                        dataSource={width > 768 ? tableData1 : tableData1Mobile}
                        showHeader={false}
                        pagination={false}
                    />

                    <div className="flex justify-between items-center mb-4 mt-12">
                        <h3>Ta'lim ma'lumotlar</h3>
                    </div>
                    <Table
                        columns={width > 768 ? columns : columnsMobile}
                        bordered
                        dataSource={width > 768 ? tableData4 : tableData4Mobile}
                        showHeader={false}
                        pagination={false}
                    />

                    <div className="flex justify-between items-center mb-4 mt-12">
                        <h3>Oldingi ta'lim muassasasi</h3>
                    </div>
                    <Table
                        columns={width > 768 ? columns : columnsMobile}
                        bordered
                        dataSource={width > 768 ? tableData2 : tableData2Mobile}
                        showHeader={false}
                        pagination={false}
                    />

                    <div className="flex justify-between items-center mb-4 mt-12">
                        <h3>{t('Exam form')}</h3>
                    </div>
                    <Table
                        columns={width > 768 ? columns : columnsMobile}
                        bordered
                        dataSource={width > 768 ? tableData3 : tableData3Mobile}
                        showHeader={false}
                        pagination={false}
                    />

                    <div className="flex justify-between items-center mb-4 mt-12">
                        <h3>{t('Shartnomalar')}</h3>
                    </div>
                    {
                        data?.data?.entered !== 1 ? <Alert description='Talaba imtihon topshirmagan!' type='warning' /> 
                        : 
                        <div>
                            <Button 
                                onClick={() => printContract({type: 2})} 
                                type="primary" 
                                size="large"
                                className="max-md:mt-2 flex items-center mb-3"
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20 7V14C20 16.2091 18.2091 18 16 18H4C1.79086 18 0 16.2091 0 14V4C0 1.79086 1.79086 0 4 0H6.66667C7.53215 0 8.37428 0.280711 9.06667 0.8L10.9333 2.2C11.6257 2.71929 12.4679 3 13.3333 3H16C18.2091 3 20 4.79086 20 7ZM9.11612 11.0555C9.15858 11.098 9.20334 11.1368 9.25 11.1718V7.00006C9.25 6.58584 9.58579 6.25006 10 6.25006C10.4142 6.25006 10.75 6.58584 10.75 7.00006V11.1718C10.7967 11.1368 10.8414 11.098 10.8839 11.0555L12.4697 9.46973C12.7626 9.17684 13.2374 9.17684 13.5303 9.46973C13.8232 9.76262 13.8232 10.2375 13.5303 10.5304L11.9445 12.1162C10.8706 13.1901 9.1294 13.1901 8.05546 12.1162L6.46967 10.5304C6.17678 10.2375 6.17678 9.76262 6.46967 9.46973C6.76256 9.17684 7.23744 9.17684 7.53033 9.46973L9.11612 11.0555Z" fill="white"/>
                                    </svg>
                                }
                            >
                                {t("Download bilateral agreement")}
                            </Button>
                            <Button 
                                onClick={() => printContract({type: 3})} 
                                type="primary" 
                                size="large"
                                className="max-md:mt-2 flex items-center"
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20 7V14C20 16.2091 18.2091 18 16 18H4C1.79086 18 0 16.2091 0 14V4C0 1.79086 1.79086 0 4 0H6.66667C7.53215 0 8.37428 0.280711 9.06667 0.8L10.9333 2.2C11.6257 2.71929 12.4679 3 13.3333 3H16C18.2091 3 20 4.79086 20 7ZM9.11612 11.0555C9.15858 11.098 9.20334 11.1368 9.25 11.1718V7.00006C9.25 6.58584 9.58579 6.25006 10 6.25006C10.4142 6.25006 10.75 6.58584 10.75 7.00006V11.1718C10.7967 11.1368 10.8414 11.098 10.8839 11.0555L12.4697 9.46973C12.7626 9.17684 13.2374 9.17684 13.5303 9.46973C13.8232 9.76262 13.8232 10.2375 13.5303 10.5304L11.9445 12.1162C10.8706 13.1901 9.1294 13.1901 8.05546 12.1162L6.46967 10.5304C6.17678 10.2375 6.17678 9.76262 6.46967 9.46973C6.76256 9.17684 7.23744 9.17684 7.53033 9.46973L9.11612 11.0555Z" fill="white"/>
                                    </svg>
                                }
                            >
                                {t("Download the tripartite agreement")}
                            </Button>
                            {
                                data?.data?.language_id === 1 ?
                                <div>
                                    <ShartNomaQabulUzNew print_ref={print_ref} data={{...data?.data?.contract, student: data?.data}} />
                                    <ShartNomaQabulThreeSideUzNew print_ref={print_ref_three_side} data={{...data?.data?.contract, student: data?.data}}/>
                                </div>
                                : <div>
                                    <ShartNomaQabulRuNew print_ref={print_ref} data={{...data?.data?.contract, student: data?.data}} />
                                    <ShartNomaQabulThreeSideRuNew print_ref={print_ref_three_side} data={{...data?.data?.contract, student: data?.data}}/>

                                </div>
                            }
                            <iframe ref={iframe_ref} style={{ height: '0px', width: '0px', position: 'absolute', opacity: 0 }}></iframe>
                            <iframe ref={iframe_ref_three_side} style={{ height: '0px', width: '0px', position: 'absolute', opacity: 0 }}></iframe>

                        </div>
                    }
                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default StudentView;