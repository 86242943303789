export function numberToWordsUz(number: number) {
    // So'zlar qatorini saqlash uchun ro'yhat
    var ones = ["", "bir", "ikki", "uch", "to'rt", "besh", "olti", "yetti", "sakkiz", "to'qqiz"];
    var tens = ["", "o'n", "yigirma", "o'ttiz", "qirq", "ellik", "oltmish", "yetmish", "sakson", "to'qson"];
  
    // Natija uchun so'zlar qatorini saqlash
    var result = "";
  
    // Yuzliklar, mingliklar, millionliklar, milliardliklar uchun qatnashuvlar
    var billions = Math.floor(number / 1000000000);
    var millions = Math.floor((number % 1000000000) / 1000000);
    var thousands = Math.floor((number % 1000000) / 1000);
    var onesPart = number % 1000;
  
    // Milliardlar uchun so'zni qo'shish
    if (billions !== 0) {
      result += numberToWordsUz(billions) + " milliard ";
    }
  
    // Millionlar uchun so'zni qo'shish
    if (millions !== 0) {
      result += numberToWordsUz(millions) + " million ";
    }
  
    // Minglar uchun so'zni qo'shish
    if (thousands !== 0) {
      result += numberToWordsUz(thousands) + " ming ";
    }
  
    // Qoldiq uchun so'zni qo'shish
    if (onesPart !== 0) {
      // Yuzliklar
      var hundreds = Math.floor(onesPart / 100);
      if (hundreds !== 0) {
        result += ones[hundreds] + " yuz ";
      }
  
      // Onliklar
      var tensPart = Math.floor((onesPart % 100) / 10);
      
      if (tensPart >= 1) {
        result += tens[tensPart] + " ";
        var onesPartRemainder = onesPart % 10;
        if (onesPartRemainder !== 0) {
          result += ones[onesPartRemainder] + " ";
        }
      } else if (tensPart === 1) {
        result += ones[onesPart % 100] + " ";
      } else if (onesPart % 10 !== 0) {
        result += ones[onesPart % 10] + " ";
      }
    }
  
    // Natijani qaytarish
    return result.trim();
}



// export function numberToWordsRu(number: number) {

//     var units = ['', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'];
//     var tens = ['', 'десять', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'];
//     var hundreds = ['','сто','двести','триста','четыреста','пятьсот','шестьсот','семьсот','восемьсот','девятьсот'];

//     var word = '';

//     if(number === 0) {
//         return 'ноль';
//     }

//     var numStr = number.toString();

//     var groupCount = Math.ceil(numStr.length / 3);

//     numStr = numStr.padStart(groupCount * 3, '0');

//     // console.log("groupCount", groupCount);
    
//     for(var i = 0; i < groupCount; i++) {
//         var group = numStr.slice(i * 3, (i + 1) * 3);
//         var groupInt = parseInt(group, 10);

//         if(groupInt !== 0) {
//             var h = Math.floor(groupInt / 100);
//             var t = Math.floor((groupInt % 100) / 10);
//             var u = groupInt % 10;

//             if(h > 0) {
//                 word += hundreds[h] + ' ';
//             }

//             if(t > 1) {
//                 word += tens[t] + ' ';
//                 word += units[u];
//             } else if(t === 1) {
//                 word += units[t * 10 + u];
//             } else if(u !== 0) {
//                 word += units[u];
//             }

//             if (groupCount - i - 1 === 1) {
//                 if (u === 1) {
//                     word += 'тысяча ';
//                 } else if (u >= 2 && u <= 4) {
//                     word += 'тысячи ';
//                 } else {
//                     word += 'тысяч ';
//                 }
//             } else if (groupCount - i - 1 === 2) {
//                 if (u === 1) {
//                     word += 'миллион ';
//                 } else if (u >= 2 && u <= 4) {
//                     word += 'миллиона ';
//                 } else {
//                     word += 'миллионов ';
//                 }
//             } else if (groupCount - i - 1 === 3) {
//                 if (u === 1) {
//                     word += 'миллиард ';
//                 } else if (u >= 2 && u <= 4) {
//                     word += 'миллиарда ';
//                 } else {
//                     word += 'миллиардов ';
//                 }
//             }
//         }
//     }

//     return word.trim();
// }


export function numberToWordsRu(n: number) {
    if (n < 0 || n > 999999999) {
        throw new Error("Number out of range");
    }

    const ones = ["ноль", "один", "два", "три", "четыре", "пять", "шесть", "семь", "восемь", "девять"];
    const teens = ["десять", "одиннадцать", "двенадцать", "тринадцать", "четырнадцать", "пятнадцать", "шестнадцать", "семнадцать", "восемнадцать", "девятнадцать"];
    const tens = ["", "", "двадцать", "тридцать", "сорок", "пятьдесят", "шестьдесят", "семьдесят", "восемьдесят", "девяносто"];
    const hundreds = ["", "сто", "двести", "триста", "четыреста", "пятьсот", "шестьсот", "семьсот", "восемьсот", "девятьсот"];
    const thousands = ["", "тысяча", "тысячи", "тысяч"];
    const millions = ["", "миллион", "миллиона", "миллионов"];

    let result = '';

    if (n === 0) {
        return ones[0];
    }

    if (Math.floor(n / 1000000) > 0) {
        result += convertHundreds(Math.floor(n / 1000000)) + " " + getMillionWord(n) + " ";
    }

    if (Math.floor((n % 1000000) / 1000) > 0) {
        result += convertHundreds(Math.floor((n % 1000000) / 1000)) + " " + getThousandWord(n % 1000000) + " ";
    }

    if (n % 1000 > 0) {
        result += convertHundreds(n % 1000);
    }

    return result.trim();

    function convertHundreds(num: number) {
        let str = '';
        if (Math.floor(num / 100) > 0) {
            str += hundreds[Math.floor(num / 100)] + " ";
        }

        if (num % 100 >= 10 && num % 100 < 20) {
            str += teens[num % 100 - 10] + " ";
        } else {
            if (Math.floor((num % 100) / 10) > 1) {
                str += tens[Math.floor((num % 100) / 10)] + " ";
            }

            if (num % 10 > 0) {
                str += ones[num % 10] + " ";
            }
        }
        return str.trim();
    }

    function getThousandWord(num: number) {
        const lastDigit = Math.floor(num / 1000) % 10;
        if (Math.floor(num / 1000) % 100 >= 11 && Math.floor(num / 1000) % 100 <= 19) {
            return thousands[3];
        } else if (lastDigit === 1) {
            return thousands[1];
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return thousands[2];
        } else {
            return thousands[3];
        }
    }

    function getMillionWord(num: number) {
        const lastDigit = Math.floor(num / 1000000) % 10;
        if (Math.floor(num / 1000000) % 100 >= 11 && Math.floor(num / 1000000) % 100 <= 19) {
            return millions[3];
        } else if (lastDigit === 1) {
            return millions[1];
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return millions[2];
        } else {
            return millions[3];
        }
    }
}