import dayjs from 'dayjs';
import { renderFullName } from '../../utils/others_functions';
import { numberToWordsRu } from '../../utils/number_to_string';
import { formatNumberWithSpace } from '../../utils/numbe_formatter';

const ShartNomaQabulRuNew = ({print_ref, data, isShow} : {print_ref: any, data: any, isShow?: boolean}) => {

    const contract_summ = data?.student?.direction?.contract_price * data?.student?.contract_type;

    return (
        <div className={isShow ? "" : "hidden"} ref={print_ref}>
            <div className="w-[793.7px] bg-white mx-auto p-5" style={{fontFamily: "sans-serif"}}>
                <div>
                    <h5 style={{textAlign: "center"}}>
                        ДОГОВОР №<br /> {data?.student?.direction?.direction_code} | {10000 + data?.id}
                        <br />
                        на 2024 -2025 учебный год по оказанию образовательных услуг на платной основе
                    </h5>
                    <div style={{display: "flex", justifyContent: "space-between", margin: "0 0 10px 0"}}>
                        <p>{dayjs.unix(data?.finish_time).format('DD-MM-YYYY HH:mm:ss')}</p>
                        <p>г. Ташкент</p>
                    </div>
                    <p style={{marginBottom: "20px"}}>От имени организации высшего образования «PERFECT UNIVERSITY» (далее – «Университет») ректор АСКАРАЛИЕВ ОДИЛБЕК УЛУГБЕК УГЛИ, действуя на основании Устава, с одной стороны,</p>
                    <p style={{textAlign: "center"}}>
                        <strong>{renderFullName(data?.student)}</strong>
                    </p>
                    <p>(далее именуемый «Студент»), с другой стороны, именуемые в дальнейшем совместно «Стороны», настоящим заключают настоящее соглашение о нижеследующем:</p>
                    <p style={{textAlign: "center", margin: "10px 0 8px 0"}}>
                        <strong>I. ПРЕДМЕТ СОГЛАШЕНИЯ</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>1.1. В соответствии с настоящим договором Университет обязуется осуществлять обучение Обучающегося на основе учебных планов и образовательных программ, утвержденных на основе государственных образовательных стандартов высшего образования в области образования и форме обучения, указанных ниже.</p>
                    <div style={{paddingLeft: '25px'}}>
                        <div style={{border: "1px solid #000", padding: "10px", alignItems: "start", margin: "8px 0"}} className="items-center">
                            <div style={{width: "46%", display: "inline-block"}}>
                                <p style={{marginBottom: "8px", display: "block"}}>Направление обучения :</p>
                                <p style={{marginBottom: "8px", display: "block"}}>Форма обучения :</p>
                                <p style={{marginBottom: "8px", display: "block"}}>Срок обучения в сфере образования :	5 лет</p>
                                <p style={{marginBottom: "8px", display: "block"}}>Курс обучения:</p>
                                <p>Общая стоимость договора (за один учебный год) :</p>
                            </div>
                            <div style={{width: "50%", display: "inline-block"}}>
                                <strong style={{marginBottom: "10px", display: "block"}}>{data?.student?.direction?.direction_code} {data?.student?.direction?.name}</strong>
                                <strong style={{marginBottom: "10px", display: "block"}}>{data?.student?.eduForm?.name}</strong>
                                <strong style={{marginBottom: "10px", display: "block"}}>{data?.student?.direction?.edu_duration || 1} лет</strong>
                                <strong style={{marginBottom: "20px", display: "block"}}>1 курс</strong>
                                <strong style={{display: "block"}}>{formatNumberWithSpace(contract_summ)} сум.</strong>
                            </div>
                        </div>
                    </div>
                    <p style={{textIndent: '25px'}}>Обучающийся обязуется получать образование и оплачивать обучение в соответствии с правилами процедуры, установленными Университетом.</p>
                    <p style={{textIndent: '25px'}}>1.2. Размер платы за обучение в вузе на основании договора (далее - плата за договор) зависит от направления обучения, формы обучения - очная, вечерняя и заочная, а также разницы в предметах, связанных с обучением. перевод обучения определяется отдельно для каждого учебного года, исходя из преподавания и полученных баллов.</p>
                    <p style={{textAlign: "center", margin: "10px 0 8px 0"}}>
                        <strong>II. ПРАВА И ОБЯЗАННОСТИ СТОРОН</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>2.1. Права Университета :</p>
                    <p style={{textIndent: '25px'}}>2.1.1. Постоянный контроль за выполнением студентом своих обязательств.</p>
                    <p style={{textIndent: '25px'}}>2.1.2. Требовать от студента выполнения договорных обязательств, соблюдения правил внутреннего распорядка, своевременной оплаты обучения по договору.</p>
                    <p style={{textIndent: '25px'}}>2.1.3. Отчисление (исключение) обучающегося из числа состава студентов без предупреждения или оставление его на соответствующем курсе за нарушение порядка осуществления контрактной оплаты, внутреннего порядка и правил образовательной программы, за пропуск занятий большего числа академических часов, определённых Университетом в течение семестра без уважительной причины.</p>
                    <p style={{textIndent: '25px'}}>2.1.4. Требовать от обучающегося соответствующие документы в установленном порядке и не включать их в приказ ректора Университета о зачислении или переводе обучающегося на следующий курс, независимо от того, что оплата контракта произведена без них.</p>
                    <p style={{textIndent: '25px'}}>2.1.5 . Не допуск обучающегося к экзамену в случаях, предусмотренных внутренними документами университета .</p>
                    <p style={{textIndent: '25px'}}>2.1.6. В случае совершения обучающимся одного из следующих действий Университет имеет право расторгнуть договор в одностороннем порядке, уведомив об этом Заказчика:</p>
                    <p style={{textIndent: '25px'}}>а) при умышленном распространении ложной информации о деятельности Университета в социальных сетях, а также неуважительном отношении к профессорско-преподавательскому составу, преподавателям и административному персоналу;</p>
                    <p style={{textIndent: '25px'}}>б) фальсификация документов, в том числе принесение на экзамен ответов на экзаменационные вопросы и другие подобные материалы, их использование во время экзамена или распространение среди других обучающихся;</p>
                    <p style={{textIndent: '25px'}}>в) Использование товарного знака/логотипа Университета без письменного разрешения Университета (для изготовления различных предметов, одежды и т.п.);</p>
                    <p style={{textIndent: '25px'}}>г) совершение сексуальной, расовой, религиозной, этнической дискриминации в отношении обучающихся, преподавателей и сотрудников Университета, нарушение правил этикета, физическое и (или) моральное давление;</p>
                    <p style={{textIndent: '25px'}}>д) систематическое (2 и более раза) умышленное нарушение требований соблюдения правил внутреннего распорядка Университета, требований внутренних документов, распространение конфиденциальной информации, относящейся к Университету или другим обучающимся, известной ему;</p>
                    <p style={{textIndent: '25px'}}>е ) выезд за границу без письменного согласия Университета во время обучения;</p>
                    <p style={{textIndent: '25px'}}>ж) пропуск в течение одного семестра 36 учебных часов или не предоставление в Университет в установленный срок оригинала документа об образовании;</p>
                    <p style={{textIndent: '25px'}}>з) заявление о переводе на обучение из другого высшего учебного заведения, в том числе иностранного высшего учебного заведения, использование поддельных документов для поступления в Университет или ложь в документах, связанных с переводом на обучение, и наличие недостоверных сведений;</p>
                    <p style={{textIndent: '25px'}}>и) в иных случаях, предусмотренных внутренними документами Университета ;</p>
                    <p style={{textIndent: '25px'}}>к) если он признан виновным в нарушении иных норм, предусмотренных действующим законодательством Республики Узбекистан .</p>
                    <p style={{textIndent: '25px'}}>2.1.7. Внесение изменений в утверждённый график занятий без снижения количества и качества предоставляемых образовательных услуг, в соответствии с действующим законодательством Республики Узбекистан и в зависимости от форс- мажорных обстоятельств, без уменьшения стоимости образовательных услуг, указанных в  условиях настоящего договора  принятие решения о переводе режима обучения на дистанционную форму</p>
                    <p style={{textIndent: '25px'}}>2.1.8. Изменение размера оплаты договора при изменении минимального размера вознаграждения за работу или суммы базового расчёта.</p>
                    <p style={{textIndent: '25px'}}>2.1.9. Продление сроков оплаты контракта.</p>
                    <p style={{textIndent: '25px'}}>2.1.10. В соответствии с действующим законодательством Республики Узбекистан и в зависимости от форс- мажорных обстоятельств  принятие решения о переводе режима обучения на дистанционную форму без уменьшения стоимости образовательных услуг, указанных в условиях настоящего договора.</p>
                    <p style={{textIndent: '25px'}}>2.2. Обязанности Университета:</p>
                    <p style={{textIndent: '25px'}}>2.2.1. Осуществлять обучение обучающегося в соответствии с государственными образовательными стандартами, требованиями законодательства в области образования, образовательными программами и условиями настоящего договора.</p>
                    <p style={{textIndent: '25px'}}>2.2.2. Обеспечение прав студента, установленных законом.</p>
                    <p style={{textIndent: '25px'}}>2.2.3. Организация учебного процесса с привлечением высококвалифицированных профессоров и преподавателей.</p>
                    <p style={{textIndent: '25px'}}>2.2.4. Отправка электронных счетов в течение учебного года.</p>
                    <p style={{textIndent: '25px'}}>2.2. 5 . Университет не обязуется:</p>
                    <p style={{textIndent: '25px'}}>2.2. 5.1 . Стипендия и финансовая поддержка студента;</p>
                    <p style={{textIndent: '25px'}}>2.2. 5.2 . Ответственность за жизнь , здоровье и личное имущество обучающегося ;</p>
                    <p style={{textIndent: '25px'}}>2.2.5.3. Ответственность по взаимным обязательствам обучающегося и заказчика .</p>
                    <p style={{textIndent: '25px'}}>2.3. Права заказчика:</p>
                    <p style={{textIndent: '25px'}}>2.3.1. Требовать от Университета и Студента выполнения своих договорных обязательств.</p>
                    <p style={{textIndent: '25px'}}>2.3.2. Контроль обучения студента в соответствии с учебным планом и программами Университета.</p>
                    <p style={{textIndent: '25px'}}>2.3.3. Запрашивать и получать информацию об освоении студентами предметов из Университета в установленном порядке.</p>
                    <p style={{textIndent: '25px'}}>2.3.4. Требовать от Университета предоставления Студенту качественного образования.</p>
                    <p style={{textIndent: '25px'}}>2.3.5. Внесение предложений по совершенствованию образовательного процесса вуза.</p>
                    <p style={{textIndent: '25px'}}>2.4. Обязанности заказчика:</p>
                    <p style={{textIndent: '25px'}}>2.4.1. Оплата контрактного вознаграждения в сроки, указанные в настоящем договоре.</p>
                    <p style={{textIndent: '25px'}}>2.4.2. Требовать от Студента строгого соблюдения Устава и внутренних процедур Университета и получения образования в соответствии с учебным планом и программами;</p>
                    <p style={{textIndent: '25px'}}>2.4.3. Своевременная оплата пропорционально сумме договорной оплаты соответственно при изменении минимального размера оплаты труда или суммы базового расчета.</p>
                    <p style={{textIndent: '25px'}}>2.5 . Права студента :</p>
                    <p style={{textIndent: '25px'}}>2.5.1 . Требовать от Университета выполнения своих договорных обязательств.</p>
                    <p style={{textIndent: '25px'}}>2.5.2. Образование на уровне требований Государственного стандарта в соответствии с учебными планами и программами, утвержденными вузом.</p>
                    <p style={{textIndent: '25px'}}>2.5.3. Использование материально-технической базы университета, включая лабораторное оборудование, инструменты , информационно-ресурсный центр и зону Wi-Fi .</p>
                    <p style={{textIndent: '25px'}}>2.5.4. Внесение предложений по совершенствованию образовательного процесса университета.</p>
                    <p style={{textIndent: '25px'}}>2.5.5 . Оплата контрактного вознаграждения в соответствии с условиями контракта .</p>
                    <p style={{textIndent: '25px'}}>2.5.6. Развитие и совершенствование знаний и навыков, использование всех возможностей, предлагаемых Университетом, а также активное участие в жизни общества в свободное от занятий и учёбы время.</p>
                    <p style={{textIndent: '25px'}}>2.5.7. Взятие академического отпуска сроком до 1 (одного) года с разрешения Университета в следующих случаях:</p>
                    <p style={{textIndent: '25px'}}>а) при значительном ухудшении состояния здоровья, подтверждённом документами лечащих врачей медицинских учреждений, входящих в государственную систему здравоохранения;</p>
                    <p style={{textIndent: '25px'}}>б) в случаях, связанных с беременностью и родами, а также отпуском по уходу за ребёнком до достижения ребёнком двухлетнего возраста;</p>
                    <p style={{textIndent: '25px'}}>в) предоставление академического отпуска в связи со смертью близкого родственника, в этом случае руководство Университета рассмотрит каждый случай отдельно и примет решение;</p>
                    <p style={{textIndent: '25px'}}>ж) в связи с мобилизацией на военную службу;</p>
                    <p style={{textIndent: '25px'}}>г) в иных случаях по решению руководства Университета.</p>
                    <p style={{textIndent: '25px'}}>2.5.8 . После успешного прохождения всех этапов обучения, получение диплома о высшем образовании Университета, который является документом о высшем образовании в Республике Узбекистан.</p>
                    <p style={{textIndent: '25px'}}>2. 6 . Обязанности студентов</p>
                    <p style={{textIndent: '25px'}}>2.6.1 . В случае заключения этого договора между Университетом и Студентом (без Заказчика) принятие на себя всех обязательств по оплате .</p>
                    <p style={{textIndent: '25px'}}>2.6.2. Получать образование в соответствии с требованиями, предъявляемыми к студентам высших учебных заведений, установленными законодательством Республики Узбекистан, а также нормативными правовыми документами, регулирующими образовательный процесс и деятельность Университета.</p>
                    <p style={{textIndent: '25px'}}>2.6.3 . Подача всех необходимых документов в соответствии с внутренними документами Университета .</p>
                    <p style={{textIndent: '25px'}}>2.6.4 . Строгое соблюдение требований внутреннего распорядка Университета, входа и выхода в Университет, правил личной и пожарной безопасности.</p>
                    <p style={{textIndent: '25px'}}>2.6.5 . Не совершать действий и поступков, противоречащих законодательству Республики Узбекистан.</p>
                    <p style={{textIndent: '25px'}}>2.6.6. Разумное использование технических и других средств обучения, а также оборудования/оборудования и иного имущества Университета.</p>
                    <p style={{textIndent: '25px'}}>2.6.7. Уведомление Университета об изменении паспортных данных, адреса проживания и номера телефона в течение пяти дней со дня их изменения.</p>
                    <p style={{textIndent: '25px'}}>2.6.8. Выезд за территорию Республики Узбекистан на основании письменного разрешения Университета .</p>
                    <p style={{textIndent: '25px'}}>2.6.9. Постоянный контроль за уровнем освоения знаний, процентом посещаемости предметов/уроков, выполнением финансовых обязательств перед Университетом.</p>
                    <p style={{textIndent: '25px'}}>2.6.10. Оплата сервисных сборов при получении студентом дополнительных услуг, не предусмотренных настоящим договором. Своевременная уплата такого штрафа(ов) в случаях, когда штраф предусмотрен за нарушение требований внутренних документов Университета.</p>
                    <p style={{textAlign: "center", margin: "10px 0"}}>
                        <strong>III. РАЗМЕР, ПОРЯДОК И УСЛОВИЯ ОПЛАТЫ ПЛАТЫ ЗА ОБУЧЕНИЕ</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>3.1. 2024-2025 за учебный год Стоимость договора составляет {formatNumberWithSpace(contract_summ)} сум.</p>
                    <p style={{textIndent: '25px'}}>3.2. Университет оставляет за собой право изменять стоимость Услуг. В этом случае заключается дополнительное соглашение и Стороны обязуются производить взаиморасчеты с учетом новой стоимости.</p>
                    <p style={{textIndent: '25px'}}>3.3. Оплата за обучение производится в следующем порядке:</p>
                    <p style={{textIndent: '25px'}}>3.3.1. До 15 сентября 2024 года — не менее 25 процентов .</p>
                    <p style={{textIndent: '25px'}}>3.3.2. К 15 декабря 2024 года — не менее 50 процентов .</p>
                    <p style={{textIndent: '25px'}}>3.3.3. До 15 февраля 2025 года — не менее 75 процентов .</p>
                    <p style={{textIndent: '25px'}}>3.3.4. До 15 апреля 2025 года – сумма неоплаченной части оплаты обучения, предусмотренной пунктом 3.1.</p>
                    <p style={{textIndent: '25px'}}>3.4. При оплате клиентом платы за договор в платежном поручении полностью указываются номер и дата заключения договора, фамилия, имя ,отчество. а также курс обучения. Днем оплаты считается день поступления денежных средств на банковский счет Университета.</p>
                    <p style={{textIndent: '25px'}}>3.5. В случае перевода студента на следующий курс (семестр) при условии повторной сдачи им академической задолженности по соответствующим предметам, договорная оплата за следующий семестр будет производиться студентом до момента сдачи академической задолженности в течение установленного срока. </p>
                    <p style={{textIndent: '25px'}}>3.6. Тот факт, что обучающийся не выучил уроки в течение срока действия настоящего Соглашения, нарушил правила внутреннего распорядка, правила этикета или учебного процесса, и в отношении него была принята мера по прекращению учебы или отчислению из состава студентов, не освобождает от финансовых обязательств по оплате стоимости обучения.</p>
                    <p style={{textIndent: '25px'}}>3.7. В случае отчисления обучающегося из  состава студентов вследствие его действий (бездействия) по инициативе Университета, уплаченные по договору средства не возвращаются.</p>

                    <p style={{textAlign: "center", margin: "10px 0"}}>
                        <strong>IV. ПОРЯДОК ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ ДОГОВОРА</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>4.1. Условия настоящего Соглашения могут быть изменены по соглашению Сторон или в соответствии с законодательством Республики Узбекистан. Все изменения и дополнения к Соглашению действительны, если они совершены в письменной форме и подписаны Сторонами или их уполномоченными представителями.</p>
                    <p style={{textIndent: '25px'}}>4.2. Настоящее Соглашение может быть расторгнуто в следующих случаях:</p>
                    <p style={{textIndent: '25px'}}>4.2.1. По соглашению сторон;</p>
                    <p style={{textIndent: '25px'}}>4.2.2. в одностороннем порядке по инициативе университета ( в случаях, предусмотренных п. 2.1.6 );</p>
                    <p style={{textIndent: '25px'}}>4.2.3. на основании юридически обязательного решения суда;</p>
                    <p style={{textIndent: '25px'}}>4.2.4. в связи с истечением срока действия договора;</p>
                    <p style={{textIndent: '25px'}}>4.2.5. В связи с успешным завершением обучения студента;</p>
                    <p style={{textIndent: '25px'}}>4.2.6. В случае прекращения деятельности университета.</p>
                    <p style={{textIndent: '25px'}}>4.3. В случае одностороннего расторжения договора по инициативе Университета на юридический адрес или адрес электронной почты Заказчика направляется соответствующее сообщение, о чем Заказчик уведомляется.</p>
                    <p style={{textAlign: "center", margin: "10px 0"}}>
                        <strong>V. ФОРС-Мажор</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>5.1. Обстоятельства, делающие невозможным полное или частичное исполнение договора одной из сторон, в частности, пожар, стихийное бедствие, война, любые военные действия, замена действующих правовых документов и иные возможные форс-мажорные обстоятельства , не зависящие от сторон случаи, сроки исполнения обязательств по договору будут продлены в соответствии со сроком действия этих случаев.</p>
                    <p style={{textIndent: '25px'}}>5.2. Сторона, неспособная исполнить свои обязательства по настоящему договору, должна немедленно уведомить другую сторону о наступлении или отмене обстоятельств, препятствующих исполнению ею этих обстоятельств.</p>
                    <p style={{textIndent: '25px'}}>Уведомление будет отправлено на юридический адрес, указанный в договоре и подтвержденный почтовым отделением-отправителем.</p>
                    <p style={{textIndent: '25px'}}>5.3. Если вследствие какого-либо события, стихийного бедствия, войны или чрезвычайного положения в стране решение, принятое органом государственной власти, его исполнение, действия, предпринятые в связи с ним (не ограничиваясь этими событиями), произошли вне контроля сторон В случае возникновения договора одна сторона не несет ответственности перед другой стороной за последствия неисполнения или просрочки исполнения настоящего договора. Сторона, исполнение которой приостановлено таким образом, имеет право на соответствующее продление срока исполнения таких обязательств.</p>
                    <p style={{textAlign: "center", margin: "10px 0"}}>
                        <strong>VI. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>6.1. Студент принимает на себя полную материальную ответственность перед Университетом в случае причинения материального ущерба имуществу, оборудованию, учебным материалам и т.п.</p>
                    <p style={{textIndent: '25px'}}>6.2. В случае несвоевременной оплаты обучения в соответствии с пунктом 3.3 настоящего договора:</p>
                    <p style={{textIndent: '25px'}}>6.2.1. Доступ студента в Университет ограничен;</p>
                    <p style={{textIndent: '25px'}}>6.2.1. Доступ студента в Университет ограничен;</p>
                    <p style={{textIndent: '25px'}}>- Предоставление академических услуг Университетом;</p>
                    <p style={{textIndent: '25px'}}>- допуск студентов к экзаменам;</p>
                    <p style={{textIndent: '25px'}}>- предоставление любых академических справок/сертификатов;</p>
                    <p style={{textIndent: '25px'}}>6.2. 3 . Студент 2.1. В соответствии с пунктом 6 студенты могут быть отчислены.</p>
                    <p style={{textIndent: '25px'}}>6.3. Университет не несет ответственности за источники оплаты контракта .</p>
                    <p style={{textIndent: '25px'}}>6.4. Университет не несет ответственности за ошибки, допущенные при оформлении оплаты контракта .</p>
                    <p style={{textIndent: '25px'}}>6.5. Отчисление Обучающегося или исключение из рядов Обучающихся не освобождает Заказчика и Обучающегося от уплаты платы за оказанные Обучающемуся образовательные услуги и иных обязательств по настоящему договору.</p>
                    <p style={{textIndent: '25px'}}>6.6. Меры ответственности сторон, не предусмотренные настоящим Соглашением, определяются действующим законодательством Республики Узбекистан.</p>
                    <p style={{textAlign: "center", margin: "10px 0"}}>
                        <strong>VII. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>7.1. Приказ Университета о зачислении Студента выдается при условии предоставления Студентом всех необходимых документов и внесения оплаты в соответствии с пунктом 3.3.1 договора.</p>
                    <p style={{textIndent: '25px'}}>7.2. Университет не выплачивает стипендию студенту.</p>
                    <p style={{textIndent: '25px'}}>7.3. Обязательства, предусмотренные пунктом 1 настоящего Соглашения, могут быть предоставлены непосредственно или в режиме онлайн в соответствии с требованиями действующего законодательства Республики Узбекистан. В случае оказания услуг академического образования в режиме онлайн Обучающийся несет личную ответственность за качество состояния технических и телекоммуникационных коммуникаций.</p>
                    <p style={{textIndent: '25px'}}>7.4. Настоящее Соглашение заключается сроком на один учебный год до полного исполнения Сторонами своих обязательств для целей, указанных в его предмете, но не позднее 1 июля 2025 года. Истечение срока действия договора не освобождает сторон-должников от исполнения своих обязательств.</p>
                    <p style={{textIndent: '25px'}}>7.5. Оригиналы официальных документов, в том числе документов об окончании (аттестата/диплома/аттестата) среднего или среднего специального учебного заведения, Студенту или иному лицу в период обучения не выдаются.</p>
                    <p style={{textIndent: '25px'}}>7.6. Университет не берет на себя никаких обязательств по трудоустройству Студента.</p>
                    <p style={{textIndent: '25px'}}>7.7. Комиссии по контракту и все банковские сборы, связанные с их возвратом, оплачиваются Заказчиком или Студентом.</p>
                    <p style={{textIndent: '25px'}}>7.8. В случаях, когда денежные средства подлежат возврату Университетом по настоящему договору, эти средства подлежат возврату в течение 30 (тридцати) дней со дня вступления в силу соответствующего документа.</p>
                    <p style={{textIndent: '25px'}}>7.9. Любые изменения и/или дополнения к настоящему Соглашению имеют силу только в том случае, если они составлены в письменной форме и подписаны/запечатаны сторонами.</p>
                    <p style={{textIndent: '25px'}}>7.10. Стороны соглашаются признать факсимиле Университета соответствующей подписью в договоре.</p>
                    <p style={{textIndent: '25px'}}>7.11. Любые споры и разногласия, возникающие из настоящего Соглашения, подлежат разрешению путем переговоров между сторонами. В случае недостижения соглашения споры будут рассматриваться судом по месту нахождения Университета в порядке, установленном законодательством Республики Узбекистан.</p>
                    <p style={{textAlign: "center", margin: "10px 0"}}>
                        <strong>VIII. ЗАКЛЮЧИТЕЛЬНЫЕ ПРАВИЛА</strong>
                    </p>
                    <p style={{textIndent: '25px'}}>8.1. Настоящее соглашение вступает в силу с момента его подписания Сторонами.</p>
                    <p style={{textIndent: '25px'}}>8.2. В случае неудовлетворения Заказчика и Студента условиями договора они должны подать заявку не позднее 30 ноября 2024 года, при этом Студент, не заключивший к этой дате договор с Университетом, не будет принят к обучению.</p>
                    <p style={{textIndent: '25px'}}>8.3. В соответствии с соответствующим постановлением Президента Республики Узбекистан при изменении минимального размера оплаты труда или базовой расчетной суммы размер контрактной оплаты увеличивается с начала следующего семестра.</p>
                    <p style={{textIndent: '25px'}}>8.4. Переписка между Сторонами по вопросам подписания, изменения, исполнения, расторжения настоящего соглашения может осуществляться через официальные адреса электронной почты Сторон, указанные в соглашении, при этом Стороны признают, что сообщения, направляемые в настоящем порядке, имеют юридическую силу. Сторона, не уведомившая другую сторону в письменном виде об изменении адреса электронной почты, несет все связанные с этим риски.</p>
                    <p style={{textIndent: '25px'}}>8.5. Настоящее Соглашение составлено на узбекском и на русском языках в трех экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру для каждой стороны.</p>
                    <div style={{marginTop: '170px'}}>
                        <p style={{textAlign: "center", margin: "10px 0"}}>
                            <strong>IX. ЮРИДИЧЕСКИЕ АДРЕСА И БАНКОВСКИЕ РЕКВИЗИТЫ СТОРОН</strong>
                        </p>
                        <div style={{display: "flex", justifyContent: "space-between", gap: "50px"}}>
                            <div style={{width: "45%"}}>
                                <p style={{marginBottom: "30px", marginTop: "30px", textAlign: "center"}}>
                                    <strong>Университет</strong>
                                </p>
                                <p style={{marginBottom: "30px"}}>
                                    <strong>"PERFECT UNIVERSITY" высшая образовательная организация</strong>
                                </p>
                                <p>
                                Адрес: город Ташкент, Юнусабадский район, МФУ Посира, улица Богишамол, 220
                                </p>
                                <p>
                                Сч/Н: 2020 8000 8055 1905 4001
                                </p>
                                <p>
                                Банк: "КАПИТАЛБАНК" АТБ Сиргалинский филиал
                                </p>
                                <p>
                                Код банка (МФО): 01042
                                </p>
                                <p>
                                ИФУТ (ОКЕД): 85420
                                </p>
                                <p>
                                СТИР (ИНН): 309477784
                                </p>
                                <p>
                                Телефон: +998 77 129-29-29
                                </p>
                                <p>
                                Телефон: +998 55 500-02-50
                                </p>
                            </div>
                            <div style={{width: "45%", marginTop: "auto", marginBottom: "auto"}}>
                                <p style={{marginBottom: "30px", marginTop: "30px", textAlign: "center"}}>
                                    <strong>Студент</strong>
                                </p>
                                <p>ФИО.: {renderFullName(data?.student)}</p>
                                <p>Паспортные данные: {data?.student?.passport_serial} {data?.student?.passport_number}</p>
                                <p>ПИНФЛ: {data?.student?.passport_pin}</p>
                                <p>Зарегистрированный номер <br />телефона: +{data?.student?.phone}</p>
                                <p>Электронная почта:  _________________________________</p>
                            </div>
                        </div>

                        <div style={{display: "flex", justifyContent: "space-between", margin: "30px 0"}}>
                            <p>M.O‘.</p>
                            <p>{renderFullName(data?.student)}</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", margin: "30px 0"}}>
                            <p>Ректор_______________ Аскаралиев О.У.</p>
                            <p>Подпись : _________________________________</p>
                        </div>

                        <div style={{display: "flex", margin: "30px 0", gap: "50px"}}>
                            <div style={{width: "50%"}}>
                                <img style={{width: "120px", height: "120px", marginLeft: "50px"}} src={data?.contract?.contract_3} alt="" />
                            </div>
                            <div>
                            <img style={{width: "120px", height: "120px"}} src={data?.contract?.license_url} alt="" />
                                <p style={{marginTop: "10px"}}>
                                    <strong>Дата и номер лицензии</strong>
                                </p>
                                <p>19.10.2022 № L-4409526</p>
                            </div>
                        </div>
                        
                    </div>
                    <div style={{marginTop: "450px"}}>
                        <p style={{borderBottom: "4px dotted #000", marginBottom: "40px"}}></p>
                        <div style={{border: "1px solid #000"}}>
                            <div>
                                <div style={{padding: "15px"}}>
                                    <p><strong>Дата: {dayjs.unix(data?.finish_time).format('DD-MM-YYYY HH:mm:ss')}</strong></p>
                                    <p><strong>СЧЕТ INVOYS: {data?.student?.invois}</strong></p>
                                    <p><strong>СУММА ОПЛАТЫ ПО ДОГОВОРУ: {formatNumberWithSpace(contract_summ)} ({numberToWordsRu(contract_summ)}) сум</strong></p>
                                    <p>Способы оплаты:</p>
                                    <p style={{textIndent: '25px'}}>Через юридические лица и кассы банков.  Для осуществления оплаты требуется – номер INVOYSа, ПИНФЛ и  ФИО студента</p>
                                    
                                    <div style={{border: "2px solid red", display: "flex", margin: "15px 0"}}>
                                        <strong style={{borderRight: "2px solid red", display: "inline-block", padding: "5px 15px"}}>{data?.student?.invois}</strong>
                                        <strong style={{borderRight: "2px solid red", display: "inline-block", padding: "5px 15px"}}>{data?.student?.passport_pin}</strong>
                                        <strong style={{display: "inline-block", padding: "5px 15px"}}>{renderFullName(data?.student)}</strong>
                                    </div>
                                    
                                    <p style={{color: "red"}}>Если назначение платежа не заполнено в установленном порядке, могут возникнуть проблемы с приемом платежа. Поэтому в процессе оплаты необходимо указать назначение платежа в указанном порядке!</p>
                                    <p style={{marginTop:"15px"}}>==============================================</p>
                                    <p style={{color: "#144176", margin: "15px 0"}}>
                                        <strong>Платежи на самом деле увеличивать для университета банк счет информация:</strong>
                                    </p>
                                    <table style={{border: "1px solid #000", borderCollapse: "collapse", width: "100%"}}>
                                        <tr style={{border: "1px solid #000", borderCollapse: "collapse"}}>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>Название принимающей организации:</strong>
                                            </td>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>ООО "ПЕРФЕКТ УНИВЕРСИТИ"</strong>
                                            </td>
                                        </tr>
                                        <tr style={{border: "1px solid #000", borderCollapse: "collapse"}}>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>Номер счета:</strong>
                                            </td>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>2020 8000 0055 1905 4002</strong>
                                            </td>
                                        </tr>
                                        <tr style={{border: "1px solid #000", borderCollapse: "collapse"}}>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>Код банка (МФО):</strong>
                                            </td>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>01042</strong>
                                            </td>
                                        </tr>
                                        <tr style={{border: "1px solid #000", borderCollapse: "collapse"}}>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>Код банка (МФО):</strong>
                                            </td>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>АО "КАПИТАЛБАНК" Сиргалинский филиал</strong>
                                            </td>
                                        </tr>
                                        <tr style={{border: "1px solid #000", borderCollapse: "collapse"}}>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>ПЕРЕСЫЛКА (ИНН):</strong>
                                            </td>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>309477784</strong>
                                            </td>
                                        </tr>
                                        <tr style={{border: "1px solid #000", borderCollapse: "collapse"}}>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>ИФУТ (ОКЕД):</strong>
                                            </td>
                                            <td style={{border: "1px solid #000", borderCollapse: "collapse", padding: "5px"}}>
                                                <strong>85420</strong>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShartNomaQabulRuNew;