import i18next from "i18next";
import instance from "../../../config/axios.config";


export const submitData = async (id: number | string | undefined, values: any) => {

    const formdata = new FormData();

    for (const key in values) {
        if(key === 'name'){
            formdata.append(`name[${i18next?.language}]`, values[key]);
        } else {
            formdata.append(key, values[key]);
        }
    }
    
    const url = id ? `/subjects/${id}` : `/subjects`
    const response = await instance({ url, method: id ? "PUT" : "POST", data: formdata });

    return response.data;
}