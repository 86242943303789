import { AxiosRequestConfig } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../config/axios.config";

export const signIn = createAsyncThunk(
    'signIn',
    async (params: { username?: string, password?: string }, { rejectWithValue }) => {

        try {
            const { username = '', password = '' } = params;

            const formdata = new FormData();

            formdata.append("phone", username);
            formdata.append("password", password);
            formdata.append("is_main", '1');

            const isHasToken = localStorage.getItem("access_token");

            const options: AxiosRequestConfig = isHasToken ?
                { url: `/users/me?is_main=1`, method: 'GET' } :
                { url: `/auth/login`, method: 'POST', data: formdata }

            const response = await instance(options);

            if (response?.status !== 200) return rejectWithValue(new Error("Authorization error!"))
            
            if (!isHasToken) {
                localStorage.setItem('access_token', response?.data?.data?.access_token);
            }

            return response?.data?.data;

        } catch (error: any) {

            return rejectWithValue(error?.response);

        }
    }
)

 
export interface IAuth {
    isLoading: boolean,
    status: 'pending' | 'success' | 'error',
    isAuthenticated: boolean,
    role: string
}

export const initialStateAuth: IAuth = {
    isLoading: false,
    status: 'success',
    isAuthenticated: false,
    role: ""
}


const authSlice = createSlice({
    name: 'auth',
    initialState: initialStateAuth,
    reducers: {
        signOut(state) {
            state.isAuthenticated = false;
            state.status = 'success';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.pending, (state) => {
            state.isLoading = true;
            state.status = 'pending';
        });
            builder.addCase(signIn?.fulfilled.type, (state, action: PayloadAction<any>) => {
                state.isAuthenticated = true;
                state.isLoading = false;
                state.role = action?.payload?.role                
            });
            builder.addCase(signIn?.rejected.type, (state, action) => {
                state = {
                    ...state,
                    isLoading: false,
                    status: 'error'
                }
            })
    }
})


export const AUTH_ACTIONS = authSlice.actions;

export default authSlice;