import {Dispatch, useEffect} from 'react'
import { Drawer } from "antd";

const VieWData = ({open, setOpen, selectedItem, setselectedItem}: {open: boolean, setOpen: Dispatch<boolean>, selectedItem: any, setselectedItem: Dispatch<any>}) => {

    const onClose = () => {
        setOpen(false);
    };
 
    useEffect(() => {
        if(!open) setselectedItem(undefined) 
    }, [open])

    return (
        <div>
            <Drawer key={3} title={"Fan ko'rish"} placement="right" width={500} onClose={onClose} open={open}>
                <div className="flex justify-between">
                    <p>Nomi:</p>
                    <p>{selectedItem?.name}</p>
                </div>
                
                {
                    selectedItem?.createdBy ? 
                    <div className='border rounded-lg p-3 bg-slate-50 my-4'>
                        <h4 className='opacity-60'>Malumotni yaratgan foydalanuvchi</h4>
                        <div className="flex justify-between mt-4">
                            <p>Login:</p>
                            <p>{selectedItem?.createdBy?.username}</p>
                        </div>
                    </div> : ""
                }
                {
                    selectedItem?.updatedBy ? 
                    <div className='border rounded-lg p-3 bg-slate-50 my-4'>
                        <h4 className='opacity-60'>Malumotni o'zgartirgan foydalanuvchi</h4>
                        <div className="flex justify-between mt-4">
                            <p>Login:</p>
                            <p>{selectedItem?.updatedBy?.username}</p>
                        </div>
                    </div> : ""
                }
            </Drawer>
        </div>
    )
}
export default VieWData;