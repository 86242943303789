import { Tag } from 'antd';
import {FaCity, FaHotel} from 'react-icons/fa';
import {PiUsersFourFill} from 'react-icons/pi';
import { MdShowChart } from 'react-icons/md';

const Dashboard = () => {

    const data = [
        {
            icon: <FaCity className='text-sky-600 text-[35px]' />,
            title: "Binolar",
            text: <Tag className='text-[16px] py-1 whitespace-normal' bordered={false} color="success">
                <span className='flex'>
                    According to the annual account
                    <MdShowChart className='text-[20px] ml-3' />
                </span>
                    </Tag>
        },
        {
            icon: <FaHotel className='text-sky-600 text-[35px]' />,
            title: "Xonalar",
            text: <Tag className='text-[16px] py-1 whitespace-normal' bordered={false} color="warning">
                        <span className='flex'>
                            According to the annual account
                            <MdShowChart className='text-[20px] ml-3' />
                        </span>
                    </Tag>
        },
        {
            icon: <FaCity className='text-sky-600 text-[35px]' />,
            title: "Xodimlar",
            text: <Tag className='text-[16px] py-1 whitespace-normal' bordered={false} color="processing">
                        <span className='flex'>
                            According to the annual account
                            <MdShowChart className='text-[20px] ml-3' />
                        </span>
                    </Tag>
        },
        {
            icon: <PiUsersFourFill className='text-sky-600 text-[35px]' />,
            title: "Talabalar",
            text: <Tag className='text-[16px] py-1 whitespace-normal' bordered={false} color="success">
                        <span className='flex'>
                            According to the annual account
                            <MdShowChart className='text-[20px] ml-3' />
                        </span>
                    </Tag>
        }
    ]

    return (
        <div>
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-4">
                {
                    data.map((item, index) => (
                        <div key={index} className="p-3 rounded-md shadow-md">
                            <div className="flex justify-between items-start mb-10">
                                <h2>{item.title}</h2>
                                {item.icon}
                            </div>
                            {item.text}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default Dashboard;