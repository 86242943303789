import { Button, Select, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import { number_order } from "../../utils/number_orders";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../components/Pagination";
import SearchInputWithoutIcon from "../../components/SearchInput/searchInputWithoutIcon";
import { useRef, useState } from "react";
import dayjs from 'dayjs';
import ShartNomaQabulUzNew from "../../components/sheetsNew/shartnoma_qabul_uz";
import ShartNomaQabulRuNew from "../../components/sheetsNew/shartnoma_qabul_ru";
import ShartNomaQabulThreeSideRuNew from "../../components/sheetsNew/shartnoma_qabul_three_side_ru";
//@ts-ignore
import ShartNomaQabulThreeSideUzNew from "../../components/sheetsNew/shartnoma_qabul_three_side_uz";

const ContractStudents = () => {

    const { urlValue, writeToUrl } = useUrlQueryParams({ currentPage: 1, perPage: 15 });
    const [selectedItem, setselectedItem] = useState<any>();
    const [last_name, setlast_name] = useState<string>();
    const [first_name, setfirst_name] = useState<string>();
    const [middle_name, setmiddle_name] = useState<string>();
    const [passport_number, setpassport_number] = useState<string>();
    const [passport_pin, setpassport_pin] = useState<string>();
    const [invoys, setinvoys] = useState<string>();
    const [phone, setphone] = useState<string>();
    const iframe_ref = useRef<HTMLIFrameElement | null>(null);
    const print_ref = useRef<any>(null);

    const iframe_ref_three_side = useRef<HTMLIFrameElement | null>(null);
    const print_ref_three_side = useRef<any>(null);


    function printContract({type}: {type: 2 | 3}) {

      let freme_current;
      let print_current;
      if(type === 2) {
          freme_current = iframe_ref?.current;
          print_current = print_ref.current;
      } else if(type === 3) {
          freme_current = iframe_ref_three_side?.current
          print_current = print_ref_three_side.current;
      }

      
      if (freme_current) {

          const iframe = freme_current;
          iframe.contentDocument?.open()
          if (print_current) {
              iframe.contentDocument?.write(print_current?.innerHTML)
          }

          const iframeDocument = freme_current.contentDocument;
          if (iframeDocument) {
              const styleElement = iframeDocument.createElement('style');
              styleElement.textContent = `
                  @media print {
                      @page { size: portrait; }
                      html{
                          padding-left: 50px;
                      }

                      *{
                          font-family: Times New Roman
                          TimesNewRomanPSMT
                          Times New Roman Bold
                          TimesNewRomanPS-BoldMT
                          Times New Roman Italic
                      }

                      p{
                          margin: 0px;
                          font-size: 15px;
                      }
                  }
                  
              `;
              iframeDocument.head.appendChild(styleElement);
          }

          iframe.contentDocument?.close();
          iframe.focus();
          iframe.contentWindow?.print()

      }

    }

    const printSelectedItem = ({item, type}: {item: any, type: 2 | 3}) => {
      setselectedItem(item);
      setTimeout(() => {
        printContract({type})
      }, 100);
    }

    const columns: ColumnsType<any> = [
        {
            title: '№',
            dataIndex: 'order',
            width: 45,
            children: [
                {
                  title: "",
                  render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isPending),
                }
            ],
        },
        {
            title: 'Familiya',
            showSorterTooltip: false,
            sorter: () => { writeToUrl({ name: "sort", value: urlValue?.filter_like?.sort === "-last_name" ? "last_name" : "-last_name" }); return 0 },
            children: [
              {
                title: <SearchInputWithoutIcon setSearchVal={setlast_name} duration={1500} filterKey="last_name" placeholder={`Search by last name...`} />,
                render: (e) => e?.last_name,
              }
            ]
        },
        {
            title: 'Ism',
            showSorterTooltip: false,
            sorter: () => { writeToUrl({ name: "sort", value: urlValue?.filter_like?.sort === "-first_name" ? "first_name" : "-first_name" }); return 0 },
            children: [
              {
                title: <SearchInputWithoutIcon setSearchVal={setfirst_name} duration={1500} filterKey="first_name" placeholder={`Search by first name...`} />,
                render: (e) => e?.first_name,
              }
            ]
        },
        {
            title: 'Otasining ismi',
            showSorterTooltip: false,
            sorter: () => { writeToUrl({ name: "sort", value: urlValue?.filter_like?.sort === "-middle_name" ? "middle_name" : "-middle_name" }); return 0 },
            children: [
              {
                title: <SearchInputWithoutIcon setSearchVal={setmiddle_name} duration={1500} filterKey="middle_name" placeholder={`Search by middle name...`} />,
                render: (e) => e?.middle_name,
              }
            ]
        },
        {
            title: "Telefon nomer",
            dataIndex: 'phone',
            key: 'phone',
            children: [
                {
                  title: <SearchInputWithoutIcon setSearchVal={setphone} duration={1500} filterKey="phone" type="number" placeholder={`Search by phone...`} />,
                  render: (e, item) => <span>+{item?.phone}</span>
                }
              ],
        },
        {
            title: "Pasport seriya raqami",
            key: 'phone',
            render: (e, item) => <span>{item?.passport_serial}{item?.passport_number}</span>,
            children: [
                {
                  title: <SearchInputWithoutIcon setSearchVal={setpassport_number} duration={1500} filterKey="passport_number" type="number" placeholder={`Search by passport  number...`} />,
                  render: (e) => `${e?.passport_serial} ${e?.passport_number}`,
                }
              ]  
        },
        {
            title: "JSHIR",
            dataIndex: 'passport_pin',
            key: 'passport_pin',
            children: [
                {
                  title: <SearchInputWithoutIcon setSearchVal={setpassport_pin} duration={1500} filterKey="passport_pin" type="number" placeholder={`Search by passport pin...`} />,
                  render: (e) => e?.passport_pin,
                }
              ]
        },
        {
            title: "Invoys",
            dataIndex: 'invoys',
            key: 'invois',
            children: [
                {
                  title: <SearchInputWithoutIcon setSearchVal={setinvoys} duration={1500} filterKey="invois" type="number" placeholder={`Search by invoys...`} />,
                  render: (e) => e?.invois,
                }
              ]
        },
        {
            title: "Ta'lim shakli",
            dataIndex: 'eduForm',
            key: 'eduForm',
            children: [
                {
                  title: "",
                  render: (e) => e?.eduForm?.name,
                }
              ],
        },
        {
            title: "Ta'lim yo'nalishi",
            dataIndex: 'direction',
            key: 'direction',
            children: [
                {
                  title: "",
                  render: (e) => e?.direction?.name,
                }
            ],
        },

        // {
        //     title: "Sana",
        //     dataIndex: 'step',
        //     key: 'step',
        //     width: 50,
        //     children: [
        //         {
        //           title: "",
        //           render: (e) => {
        //             if(e?.examStudent?.length){
        //               if(e?.examStudent[0]?.download_contract) return <span>{dayjs.unix(e?.examStudent[0]?.download_contract).format('DD-MM-YYYY HH:mm:ss')}</span>
        //             }
        //             return <span>Shartnoma yuklab olingan</span>
        //           },
        //         }
        //     ],
        // },
        {
          title: "Shartnoma",
          key: 'step1',
          width: 50,
          children: [
              {
                title: "",
                render: (e) => {
                  return e?.entered === 1 ? 
                    <div>
                      <Button onClick={() => printSelectedItem({item: e, type: 2})} type="link" className="flex items-center">
                            Ikki tomonlama shartnomani yuklab olish
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 ml-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                              </svg>
                      </Button>
                      <Button onClick={() => printSelectedItem({item: e, type: 3})} type="link" className="flex items-center">
                          Uch tomonlama shartnomani yuklab olish
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 ml-3">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                            </svg>
                      </Button>
                    </div>
                  : "Yakunlanmagan!"
                },
              }
          ],
      },
    ];

    const { data, isPending } = useGetAllData({
        queryKey: ["students", "contracts", urlValue, last_name, middle_name, first_name, passport_pin, passport_number, phone],
        url: `students`,
        urlParams:{
            expand: "eduForm,contract",
            filter: JSON.stringify(urlValue.filter),
            page: urlValue.currentPage,
            'filter-like': JSON.stringify({ last_name, middle_name, first_name, passport_pin, passport_number, phone, entered: 1 }),
            'per-page': urlValue.perPage,
            sort: urlValue.filter_like?.sort ?? "-id",
        }
    })

    const { data: eduForms, isFetching: eduFormLoading } = useGetAllData({
        queryKey: ["edu-forms"],
        url: "edu-forms"
    });

    const { data: directions, isFetching: directionLoading } = useGetAllData({
        queryKey: ["directions", urlValue.filter.edu_form_id],
        url: "directions",
        urlParams:{
            filter: JSON.stringify({edu_form_id: urlValue.filter.edu_form_id})
        },
        options: {
            enabled: !!urlValue.filter.edu_form_id
        }
    }); 

    return(
        <div>
            <PageHeader
                breadCrumbData={[
                { name: "Asosiy panel", path: '/' },
                { name: "Talabalar", path: '/students' }
                ]}
                title={"Talabalar"}
            />
            <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 my-4 gap-4">
                <Select
                  size="large"
                    className="w-full"
                    allowClear
                    placeholder="Ta'lim shakli bo'yicha filter"
                    optionFilterProp="children"
                    loading={eduFormLoading}
                    onChange={(e) => writeToUrl({name: "edu_form_id", value: e})}
                    options={eduForms?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
                />
                <Select
                  size="large"
                    disabled={!urlValue.filter.edu_form_id}
                    className="w-full"
                    allowClear
                    placeholder="Yo'nalish bo'yicha filter"
                    optionFilterProp="children"
                    loading={directionLoading}
                    onChange={(e) => writeToUrl({name: "direction_id", value: e})}
                    options={directions?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
                />
            </div>
            <Table size="small" columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending} pagination={false} scroll={{x: 1750}} />

            {(data?._meta?.totalCount ?? 0) > 10 ? <CustomPagination totalCount={data?._meta.totalCount} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}
            
            {
              selectedItem ? <>
                <iframe key={selectedItem?.id} ref={iframe_ref} style={{ height: '0px', width: '0px', position: 'absolute', opacity: 0 }}></iframe>
                <iframe key={selectedItem?.id} ref={iframe_ref_three_side} style={{ height: '0px', width: '0px', position: 'absolute', opacity: 0 }}></iframe>

                  {selectedItem?.language_id === 1 ?
                    <div>
                        <ShartNomaQabulUzNew print_ref={print_ref} data={{...selectedItem?.contract, student: selectedItem}}/>
                        <ShartNomaQabulThreeSideUzNew print_ref={print_ref_three_side} data={{...selectedItem?.contract, student: selectedItem}}/>
                    </div>
                    : <div>
                        <ShartNomaQabulRuNew print_ref={print_ref} data={{...selectedItem?.contract, student: selectedItem}} />
                        <ShartNomaQabulThreeSideRuNew key={selectedItem?.id} print_ref={print_ref_three_side} data={{...selectedItem?.contract, student: selectedItem}}/>
                      </div>
                  }
              </> : ""
            }
                    
        </div>
    )
}

export default ContractStudents;