

export const examTypes = [
    {
        value: 2,
        label: "Imtihon topshirish orqali",
    },
    {
        value: 1, 
        label: "UZBMB natijalari orqali",
    },
]

export const generalEduTypes = [
    {
        value: 1,
        label: "Birinchi kurs uchun",
    },
    {
        value: 2, 
        label: "O'qishni ko'chirish uchun",
    },
]

export const userSteps = [
    {
        value: 2,
        label: "Ta'lim ma'lumotlarini kiritmagan",
    },
    {
        value: 5,
        label: "Ma'lumotlarini tasdiqlamagan",
    },
    {
        value: 6,
        label: "Ro'yxatdan to'liq o'tgan",
    },
]