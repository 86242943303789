import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, DatePicker, UploadFile, Input, Spin, Select, InputNumber, message } from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { submitExam } from './requests';
import { Notification } from '../../../components/Notifications/notification';
import useGetOneData from '../../../cutom-hooks/useGetOneData';
import { FILE_URL } from '../../../config/utils';
import PageHeader from '../../../components/HeaderPage';
import { CLIENT_API } from '../../../services/client.request';
import { useTranslation } from 'react-i18next';
import { getLanguages } from '../../../utils/language';
const { RangePicker } = DatePicker;

const dateParserToDatePicker = (second: number) => dayjs(dayjs((new Date(Number(second) * 1000))).format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm')

const ExamUpdate: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams()

  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [fileList, setfileList] = useState<UploadFile[]>([] as UploadFile[]);
  const [languages, setLanguages] = useState<any>();
  const [languageId, setLanguageId] = useState<number>();

  const { mutate } = useMutation({
    mutationFn: (newVals) => submitExam(id, newVals),
    onSuccess: async (res) => {
      Notification("success", id ? "update" : "create", res?.message)
      navigate(-1)
    },
    onError: (error: AxiosError<any>) => {
      Notification("error", id ? "update" : "create", error?.response?.data ? error?.response?.data?.message : "");
    },
    retry: 0,
  });


  const { data, isFetching: getIsLoading, isError } = useGetOneData<any>({
    queryKey: ['exams', id],
    url: `exams/${id}?expand=direction,eduYear`,
    options: {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: (!!id && id != '0'),
    }
  });

  useEffect(() => {
    if (data?.status == 1) {
      let resData = data?.data
      form.setFieldsValue({
        language_id: resData?.language_id,
        edu_year_id: resData?.edu_year_id,
        direction_id: resData?.direction_id,
        duration_time: resData?.duration_time,
        date: [dateParserToDatePicker(resData?.start_time), dateParserToDatePicker(resData?.finish_time)],
      })
      setLanguageId(resData?.language_id)
    }
  }, [data])

  useEffect(() => {
    if (isError) message.error("Malumot yuklashda xatolik!")
  }, [isError]);

  useEffect(() => {
    (
      async () => {
        const response = await getLanguages();
        setLanguages(response)
      }
    )()
  }, [])

  const { data: eduyear, isFetching: isLoadingEduyear } = useQuery<any>({
    queryKey: ["edu-years"],
    queryFn: () => CLIENT_API.getOne({ url: `/edu-years` }),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const { data: direction, isFetching: isLoadingdirection } = useQuery<any>({
    queryKey: ["directions", languageId],
    queryFn: () => CLIENT_API.getOne({ url: `/directions?expand=eduForm`, _params: {"per-page": 0, filter: JSON.stringify({language_id: languageId})} }),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!languageId
  });

  const title = id ? ((data?.data?.direction?.name + " (" + data?.data?.eduYear?.name + ")") ?? "") : `Imtihon yaratish`;

  return (
    <Spin spinning={getIsLoading} >
      <PageHeader
        title={title}
        breadCrumbData={[
          { name: "Home", path: '/' },
          { name: "Exams", path: '/exams' },
          { name: title, path: '/exams/create' }
        ]}
        isBack={true}
      />
      <div className="px-[24px] py-[20px]">
        <Form
          initialValues={{ status: true }}
          form={form}
          layout="vertical"
          onFinish={(values) => mutate({ ...values, upload_file: fileList[0]?.originFileObj })}
          size='large'
        >
          <Row gutter={24} className="mb-[50px]">
            <Col xxl={18} lg={20}>
              <Row gutter={[12, 12]}>
                <Col span={6}>
                  <Form.Item
                    label="Ta'lim tili"
                    name="language_id"
                    rules={[{ required: true, message: 'Please input language!' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a language"
                      optionFilterProp="children"
                      onChange={e => {
                        setLanguageId(e);
                        form.setFieldsValue({direction_id: undefined})
                      }}
                      options={languages?.items?.map((e: any, i: number) => ({ value: e?.id, label: e?.name }))}
                      allowClear
                      filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    label="Yo'nanlish"
                    name="direction_id"
                    rules={[{ required: true, message: 'Please input direction!' }]}
                  >
                    <Select
                      disabled={!languageId}
                      showSearch
                      placeholder="Select a direction"
                      optionFilterProp="children"
                      options={direction?.data?.items?.map((e: any, i: number) => ({ value: e?.id, label: `${e?.name} (${e?.eduForm?.name})` }))}
                      loading={isLoadingdirection}
                      allowClear
                      filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Ta'lim yili"
                    name="edu_year_id"
                    rules={[{ required: true, message: 'Please input edu year!' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a edu year"
                      optionFilterProp="children"
                      options={eduyear?.data?.items?.map((e: any, i: number) => ({ value: e?.id, label: e?.name }))}
                      loading={isLoadingEduyear}
                      allowClear
                      filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Davomiyligi (minut)"
                    name="duration_time"
                    rules={[{ required: true, message: 'Please input duration!' }]}
                  >
                    <InputNumber className="w-full" min={0} placeholder="Imtihon davomiyligi" />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    label={t("Imtihon sanasi")}
                    name={`date`}
                    shouldUpdate
                    rules={[{ required: false, message: `Please input date` }]}
                  >
                    <RangePicker
                      className="w-[100%]"
                      showTime={{ format: 'HH:mm' }}
                      format="DD-MM-YYYY HH:mm"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="flex justify-end bg-white px-[24px]">
            <Button htmlType="button" onClick={() => form.resetFields()}>{t("Reset")}</Button>
            <Button type="primary" className="ml-3" htmlType="submit">{t("Submit")}</Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default ExamUpdate;