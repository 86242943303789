import { FC } from 'react';
import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';

export type BreadcrumbArgumentsType = {
    arr: {
        name: string,
        path: string
    }[] | undefined,
    isGoBack?: boolean,
}

const CustomBreadcrumb: FC<BreadcrumbArgumentsType> = ({ arr, isGoBack= false }): JSX.Element => {
    const navigate = useNavigate();
    const path: any = (element: { name: string, path: string }) => element.path.startsWith('/') ? element.path : `/${element.path}`

    return (
        <>
            <div className="d-f">
                {isGoBack ? <HiArrowNarrowLeft className="cursor-pointer text-[25px] me-2 transi ease-out duration-300 hover:text-blue-500" onClick={() => navigate(-1)} /> : null}
                <Breadcrumb>
                    {
                        arr?.length && arr.map((element: { name: string, path: string }, index: number) => {
                            if (arr.length - 1 !== index) {
                                return (
                                    <Breadcrumb.Item key={index} className="cursor-pointer " onClick={() => navigate(path(element))}>{element.name}</Breadcrumb.Item>
                                )
                            } else {
                                return <Breadcrumb.Item key={index} className=''>{element.name}</Breadcrumb.Item>
                            }
                        })
                    }
                </Breadcrumb>
            </div>
        </>
    )
}


export default CustomBreadcrumb;