import { Button, Table } from "antd"
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import {useNavigate, useParams} from "react-router-dom";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import { useState } from "react";
import AddDiractionSubject from "./crud/update";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";

const DirectionSubjects = () => {

    const navigate = useNavigate()
    const { direction_id } = useParams();
    const [open, setOpen] = useState<boolean>(false);
    const [subjectInfo, setSubjectInfo] = useState();
    const {urlValue } = useUrlQueryParams({})

    const {data, refetch, isPending} = useGetAllData<any>({
        queryKey: ["direction-subjects", direction_id],
        url: "direction-subjects?expand=subject,direction",
        urlParams: {filter: JSON.stringify({direction_id})},
        options: {
            enabled: !!direction_id
        }
    })

    const columns: ColumnsType<any> = [
        {
            title: '#',
            key: 'index',
            width: 60,
            render: (a, b, c) => c + 1,
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'mainPhoto',
            render: (value) => <span>{value?.name}</span>
        },
        {
            title: 'Blok',
            dataIndex: 'type',
            render: (e) => <span>{e == 1 ? "1 blok" : e == 2 ? "2 blok" : ""}</span>,
            key: 'title',
        },
        {
            title: 'Ball',
            dataIndex: 'ball',
            key: 'title',
        },
        {
            title: 'Question count',
            dataIndex: 'question_count',
            key: 'description',
            render: (e) => <p dangerouslySetInnerHTML={{__html: e}} />
        },
        {
            title: 'Actions',
            key: 'id',
            dataIndex: "id",
            width:90,
            render: (_, item) => <Actions
                id={_}
                url={'direction-subjects'}
                refetch={refetch}
                onClickEdit={() => {setOpen(true); setSubjectInfo(item)}}
                onClickView={() => {setOpen(false)}}
            />
        }
    ];


    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    {name: "Asosiy panel", path: '/'},
                    {name: "Yo'nalishlar", path: `/directions`},
                    {name: "Yo'nalish fanlari", path: `/direction/subjects/${direction_id}`}
                ]}
                isBack
                title={ urlValue?.filter_like?.direction_name ?? "Yo'nalish fanlari"}
                btn={
                    <Button size="large" onClick={() => {setOpen(true); setSubjectInfo(undefined)}} type="primary">Qo'shish</Button>
                }
            />
            <Table columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending}/>

            <AddDiractionSubject open={open} setOpen={setOpen} refetch={refetch} subjectInfo={subjectInfo} setSubjectInfo={setSubjectInfo} />
        </div>
    )
}

export default DirectionSubjects;