
/**
 * @param seconds secund (number)
 * @returns secund => HH:mm:ss format (string)
 */
export const toHHmmss = (second: number) => {
  const date = (new Date(second * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/);

  if(date?.length) return date[0];
  else return "00:00:00"
}

export const renderFullName = (data: any) => {

  return `${data?.first_name ? data?.first_name : ""} ${data?.last_name ? data?.last_name : ""} ${data?.middle_name ? data?.middle_name : ""}`
}