import { Tooltip } from "antd";
import { MdDelete, MdModeEditOutline } from 'react-icons/md'
import DeleteData from "../DeleteData";
import { HiEye } from "react-icons/hi";

type TypeActions = {
  id: number,
  url: string,
  onClickView: (e?:any) => void,
  onClickEdit: (e?:any) => void,
  refetch?: any
}

const Actions: React.FC<TypeActions> = ({ id, url, onClickView, onClickEdit, refetch }) => {

  return (
    <>
      <div className="actions w-[100px]" >
        <Tooltip placement="topLeft" title={"View"}><HiEye className="view cursor-pointer text-[17px]" onClick={onClickView} /></Tooltip>
        <Tooltip placement="topLeft" title={"Edit"}><MdModeEditOutline className="edit mx-2 cursor-pointer text-[17px]" onClick={onClickEdit} /></Tooltip>
        <Tooltip placement="left" title={"Delete"}>
            <DeleteData refetch={refetch} url={url} id={id}><MdDelete className="delete cursor-pointer text-[17px]" /></DeleteData>
        </Tooltip>
      </div>
    </>
  )
}

export default Actions;