import {useEffect, useState} from "react";
import { Button, Select, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import UpdateData from "./crud/update";
import { number_order } from "../../utils/number_orders";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../components/Pagination";
import VieWData from "./crud/view";
import { Link } from "react-router-dom";
import { LuMoveRight } from "react-icons/lu";
import { getLanguages } from "../../utils/language";
import { useTranslation } from "react-i18next";

const Directions = () => {

    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [openView, setOpenView] = useState<boolean>(false);
    const [languages, setLanguages] = useState<any>();
    const {t} = useTranslation();

  const [selectedItem, setselectedItem] = useState<any>();
  const { urlValue, writeToUrl } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

    const columns: ColumnsType<any> = [
        {
            title: '№',
            dataIndex: 'order',
            render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isPending),
            width: 45,
        },
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Kodi',
            dataIndex: 'direction_code',
            key: 'direction_code',
        },
        {
            title: "O'qish davomiyligi",
            dataIndex: 'edu_duration',
            key: 'edu_duration',
        },
        {
            title: 'Konrakt summasi',
            dataIndex: 'contract_price',
            key: 'contract_price',
        },
        {
            title: "Ta'lim shakli",
            dataIndex: 'eduForm',
            key: 'eduForm',
            render: (e) => e?.name,
        },
        {
            title: "Ta'lim tili",
            dataIndex: 'language',
            key: 'language',
            render: (e) => e?.name,
        },
        {
            title: "Yo'nalish fanlari",
            key: 'direction_subjects',
            render: (_, record) => <Link to={`/direction/${record?.id}/subjects?direction_name=${record?.name}`}><LuMoveRight className="text-[25px] hover:translate-x-[3px] transition" /></Link>,
        },
        {
            title: 'Amallar',
            key: 'action',
            render: (_, record) => <Actions
                id={record?.id}
                url={'directions'}
                refetch={refetch}
                onClickEdit={() => {setOpenUpdate(true); setselectedItem(record)}}
                onClickView={() => {setOpenView(true); setselectedItem(record)}}
            />,
        },
    ];

    useEffect(() => {
        (
          async () => {
            const response = await getLanguages();
            setLanguages(response)
          }
        )()
    }, [])

    const { data: eduForms, isPending: selectLoading } = useGetAllData({
        queryKey: ["edu-forms"],
        url: "edu-forms"
    })  

    const { data, refetch, isPending } = useGetAllData({
        queryKey: ["directions", urlValue],
        url: "directions",
        urlParams:{
            expand: "createdBy,updatedBy,eduForm,language",
            page: urlValue.currentPage,
            'per-page': urlValue.perPage,
            filter: JSON.stringify(urlValue.filter)
        }
    })

    return(
        <div>
            <PageHeader
                breadCrumbData={[
                { name: "Asosiy panel", path: '/' },
                { name: "Yo'nalishlar", path: '/directions' }
                ]}
                title={"Yo'nalishlar"}
                btn={
                    <Button size="large" onClick={() => {setOpenUpdate(true); setselectedItem(undefined)}} type="primary">Qo'shish</Button>
                }
            />
            <div className="grid grid-cols-4 my-4 gap-4">
                <Select
                    size="large"
                    className="w-full"
                    allowClear
                    placeholder="Ta'lim tili bo'yicha filter"
                    optionFilterProp="children"
                    onChange={(e) => writeToUrl({name: "language_id", value: e})}
                    options={languages?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
                />
                <Select
                    size="large"
                    className="w-full"
                    allowClear
                    placeholder="Ta'lim shakli bo'yicha filter"
                    optionFilterProp="children"
                    loading={selectLoading}
                    onChange={(e) => writeToUrl({name: "edu_form_id", value: e})}
                    options={eduForms?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
                />
            </div>
            <Table columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending} pagination={false} />

            {(data?._meta?.totalCount ?? 0) > 10 ? <CustomPagination totalCount={data?._meta.totalCount} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}

            <VieWData open={openView} setOpen={setOpenView} selectedItem={selectedItem} setselectedItem={setselectedItem} />
            <UpdateData open={openUpdate} setOpen={setOpenUpdate} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
        </div>
    )
}

export default Directions;