import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import FormTestQuestionUI from './question_ui';
import FormExamTestOptionUI from './option_ui';
import useGetOneData from '../../../../cutom-hooks/useGetOneData';
import { ITestOption, ITestQuestion } from '../../../../models/subject';
import useGetAllData from '../../../../cutom-hooks/useGetAllData';
import PageHeader from '../../../../components/HeaderPage';
import DeleteData from '../../../../components/DeleteData';

const UpdateTest = () => {

  const { t } = useTranslation();
  const { subject_id, test_id } = useParams()
  const [options, setOptions] = useState<Array<any>>([]);
  const [isEdit, setisEdit] = useState<boolean>(!test_id);

  const { data, refetch, isLoading } = useGetOneData<ITestQuestion>({
    queryKey: ["questions", test_id],
    url: `questions/${test_id}`,
    options: {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: (!!test_id),
    },
  });

  const { data: optionsData, refetch: refetchOptions } = useGetAllData<ITestOption>({
    queryKey: ["options", test_id],
    url: `options`,
    urlParams: {filter: JSON.stringify({question_id: Number(test_id)})},
    options: {
      onSuccess: () => {
        setOptions([])
      },
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: (!!test_id),
    },
  });

  return (
    <Spin spinning={isLoading && !!test_id} size="small">
      <div>
        <PageHeader
          title={!test_id ? "Test qo'shish" : "O'zgatirish"}
          isBack={`subjects/view/${subject_id}?user-block=exam-tests`}
          breadCrumbData={[
            { name: "Asosiy sahifa", path: "/" },
            { name: `Fanlar`, path: `/subjects` },
            { name: `Testlar`, path: `/subject/${subject_id}/tests` },
            { name: !test_id ? "Test qo'shish" : "O'zgatirish", path: `/subject/${subject_id}/tests/${test_id}` },
          ]}
          btn={
            <div className="flex items-center">
              <DeleteData
                refetch={refetch}
                url={"tests"}
                id={Number(test_id)}
                navigateUrl={`/subject/${subject_id}/tests/${test_id}`}
              >
                <Button type="primary" className="mr-2" danger ghost>{t("O'chirish")}</Button>
              </DeleteData>
              <Button onClick={() => setisEdit(true)} htmlType="submit" >{t("O'zgartirish")}</Button>
            </div>
          }
        />
        <div className="pb-[24px]">
          <FormTestQuestionUI data={data?.data} refetch={refetch} isEdit={isEdit} setisEdit={setisEdit} />
          {
            optionsData?.items?.map((item, index) => (
              <FormExamTestOptionUI key={item?.id} data={item} refetch={refetchOptions} />
            ))
          }
          {
            options?.map((item, index) => (
              <FormExamTestOptionUI key={index} data={{} as ITestOption} refetch={refetchOptions} isNew={true} setOptions={setOptions} />
            ))
          }
          <div className="grid grid-cols-12">
            <div className="lg:col-span-8 col-span-12 lg:col-start-3">
              {
                test_id && options?.length < 1 ? <Button onClick={() => setOptions([...options, 'prev']) } className='w-[100%]' >+ {t("Variant qo'shish")}</Button> : ""
              }
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default UpdateTest;