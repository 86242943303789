import { Button } from "antd";
import { FC } from "react";

export const ExcelBtn: FC<{ onClick: any, text?: string, loading?: boolean }> = ({ onClick, text, loading = false }): JSX.Element => {
  
    return (
      <Button
        color="#52C41A"
        // icon={<TableRegular fontSize={16} color="#52C41A" />}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" className="text-[#52C41A] hover:text-blue-400 w-[20px] h-[20px]" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path className="" stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>

        }
        className="d-f text-[#52C41A] border-[#52C41A]"
        onClick={onClick}
        loading={loading}
      >
        &nbsp;&nbsp;{ text ?? "Export excel"}
      </Button>
    )
}