// export const BASE_URL = "http://192.168.155.83:8081/api/web/";
// export const FILE_URL = "http://192.168.155.83:8081/api/web/";


export const BASE_URL = "https://api-qabul.perfectuniversity.uz/api/web/";
export const FILE_URL = "https://api-qabul.perfectuniversity.uz/api/web/";


//  server URL tpu.uz
// export const BASE_URL = "http://api-qabul.tpu.uz/";
// export const FILE_URL = "http://api-qabul.tpu.uz/";

//  Ikbol's server URL
// export const BASE_URL = "http://qabul-api.z7.uz/api/web/";
// export const FILE_URL = "http://qabul-api.z7.uz/api/web/";

//  Ikbol's server URL
// export const BASE_URL = "http://qabul-api.z7.uz.uz/api/web/";
// export const FILE_URL = "http://perfect-qabul.z7.uz/api/web/";