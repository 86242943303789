import { Select, Table, Tag } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import { number_order } from "../../utils/number_orders";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import SearchInputWithoutIcon from "../../components/SearchInput/searchInputWithoutIcon";
import { useState } from "react";
import { excelExport } from "../../utils/excelExport";
import instance from "../../config/axios.config";
import { ExcelBtn } from "../../components/Buttons";

const TakenExamStudents = () => {

    const navigate = useNavigate()
    const { urlValue, writeToUrl } = useUrlQueryParams({ currentPage: 1, perPage: 15 });
    const [last_name, setlast_name] = useState<string>();
    const [first_name, setfirst_name] = useState<string>();
    const [middle_name, setmiddle_name] = useState<string>();
    const [passport_number, setpassport_number] = useState<string>();
    const [passport_pin, setpassport_pin] = useState<string>();
    const [phone, setphone] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false)

    const columns: ColumnsType<any> = [
        {
            title: '№',
            dataIndex: 'order',
            width: 45,
            children: [
                {
                  title: "",
                  render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isPending),
                }
            ],
        },
        {
            title: 'Familiya',
            showSorterTooltip: false,
            sorter: () => { writeToUrl({ name: "sort", value: urlValue?.filter_like?.sort === "-last_name" ? "last_name" : "-last_name" }); return 0 },
            children: [
              {
                title: <SearchInputWithoutIcon setSearchVal={setlast_name} duration={1500} filterKey="last_name" placeholder={`Search by last name...`} />,
                render: (e) => e?.last_name,
              }
            ]
        },
        {
            title: 'Ism',
            showSorterTooltip: false,
            sorter: () => { writeToUrl({ name: "sort", value: urlValue?.filter_like?.sort === "-first_name" ? "first_name" : "-first_name" }); return 0 },
            children: [
              {
                title: <SearchInputWithoutIcon setSearchVal={setfirst_name} duration={1500} filterKey="first_name" placeholder={`Search by first name...`} />,
                render: (e) => e?.first_name,
              }
            ]
        },
        {
            title: 'Otasining ismi',
            showSorterTooltip: false,
            sorter: () => { writeToUrl({ name: "sort", value: urlValue?.filter_like?.sort === "-middle_name" ? "middle_name" : "-middle_name" }); return 0 },
            children: [
              {
                title: <SearchInputWithoutIcon setSearchVal={setmiddle_name} duration={1500} filterKey="middle_name" placeholder={`Search by middle name...`} />,
                render: (e) => e?.middle_name,
              }
            ]
        },
        {
            title: "Telefon nomer",
            dataIndex: 'phone',
            key: 'phone',
            children: [
                {
                  title: <SearchInputWithoutIcon setSearchVal={setphone} duration={1500} filterKey="phone" type="number" placeholder={`Search by phone...`} />,
                  render: (e, item) => <span>+{item?.phone}</span>
                }
              ],
        },
        {
            title: "Pasport seriya raqami",
            key: 'phone',
            render: (e, item) => <span>{item?.passport_serial}{item?.passport_number}</span>,
            children: [
                {
                  title: <SearchInputWithoutIcon setSearchVal={setpassport_number} duration={1500} filterKey="passport_number" type="number" placeholder={`Search by passport  number...`} />,
                  render: (e) => `${e?.passport_serial} ${e?.passport_number}`,
                }
              ]  
        },
        {
            title: "JSHIR",
            dataIndex: 'passport_pin',
            key: 'passport_pin',
            children: [
                {
                  title: <SearchInputWithoutIcon setSearchVal={setpassport_pin} duration={1500} filterKey="passport_pin" type="number" placeholder={`Search by passport pin...`} />,
                  render: (e) => e?.passport_pin,
                }
              ]
        },
        {
            title: "Ta'lim shakli",
            dataIndex: 'eduForm',
            key: 'eduForm',
            children: [
                {
                  title: "",
                  render: (e) => e?.eduForm?.name,
                }
              ],
        },
        {
            title: "Ta'lim yo'nalishi",
            dataIndex: 'direction',
            key: 'direction',
            children: [
                {
                  title: "",
                  render: (e) => e?.direction?.name,
                }
            ],
        },
        {
            title: "Chet til sertifikati",
            dataIndex: 'edu_type',
            key: 'edu_type',
            render: (e) => e?.name,
            children: [
                {
                  title: "",
                  render: (e) => e?.certificate_type === 1 ? <Tag color="green">Fayli bor</Tag> : <Tag color="red">Fayli yo'q</Tag>,
                }
            ],
        },
        // {
        //     title: "Tamomlagan muassasa",
        //     dataIndex: 'exam_type',
        //     key: 'exam_type',
        //     children: [
        //         {
        //           title: "",
        //           render: (e) => examTypes.find(i => i?.value == e?.exam_type)?.label,
        //         }
        //     ],
        // },
        {
            title: "Status",
            dataIndex: 'step',
            key: 'step',
            width: 50,
            children: [
                {
                  title: "",
                  render: (e) => e?.step === 6 ? <Tag color="green">Tasdiqlagan</Tag> : <Tag color="red">Tasdiqlamagan</Tag>,
                }
            ],
        },
        {
            title: 'Amallar',
            key: 'action',
            width: 80,
            children: [
                {
                  title: "",
                  render: (_, record) => <Actions
                    id={record?.id}
                    url={'students'}
                    refetch={refetch}
                    onClickEdit={() => {}}
                    onClickView={() => navigate(`/students/${record?.id}`)}
                  />,
                }
            ],
        },
    ];

    const { data, refetch, isPending } = useGetAllData({
        queryKey: ["students", "taken-exams", urlValue, last_name, middle_name, first_name, passport_pin, passport_number, phone],
        url: `students`,
        urlParams:{
            expand: "createdBy,updatedBy,eduForm",
            filter: JSON.stringify(urlValue.filter),
            page: urlValue.currentPage,
            'per-page': urlValue.perPage,
            'filter-like': JSON.stringify({ last_name, middle_name, first_name, passport_pin, passport_number, phone, entered: 1 }),
            sort: urlValue.filter_like?.sort ?? "-id",
        }
    })

    const { data: eduForms, isFetching: eduFormLoading } = useGetAllData({
        queryKey: ["edu-forms"],
        url: "edu-forms"
    });

    const { data: directions, isFetching: directionLoading } = useGetAllData({
        queryKey: ["directions", urlValue.filter.edu_form_id],
        url: "directions",
        urlParams:{
            filter: JSON.stringify({edu_form_id: urlValue.filter.edu_form_id})
        },
        options: {
            enabled: !!urlValue.filter.edu_form_id
        }
    });

    const exportExcel = async () => {
      const arr: any = [];
      setLoading(true);
      const res = await instance({
        method: "GET",
        url: `students?sort=-id&expand=eduForm`,
        params: { 
          "per-page": 0, 
          'filter-like': JSON.stringify({ last_name, middle_name, first_name, passport_pin, passport_number, phone, entered: 1 }),
          filter: JSON.stringify(urlValue.filter),
          sort: urlValue.filter_like?.sort ?? "-id",
          step: urlValue.filter_like?.step
        }
      });
      
      
      if(res?.data?.data?.items?.length){
        res?.data?.data?.items?.forEach((element: any) => {
          arr.push({
            ["Familiya"]: element?.last_name,
            ["Ism"]: element?.first_name,
            ["Otasining ismi"]: element?.middle_name,
            ["Passport seriya nomer"]: element?.passport_serial + element?.passport_number,
            ["JSHSHIR"]: element?.passport_pin,
            ["Telefon nomer"]: element?.phone,
            ["Tug'ilgan sanasi"]: element?.birthday,
            ["Invoys raqam"]: element?.invois,
            ["Ta'lim tili"]: element?.language?.name,
            ["Ta'lim shakli"]: element?.eduForm?.name,
            ["Ta'lim yo'nalishi"]: element?.direction?.name,
          })
        })
      }
      setLoading(false);
      excelExport(arr, `Imtihon topshirgan talabalar`);
  
    }

    return(
        <div>
            <PageHeader
                breadCrumbData={[
                { name: "Asosiy panel", path: '/' },
                { name: "Imtihon topshirgan talabalar", path: '/students' }
                ]}
                title={"Imtihon topshirgan talabalar"}
                btn={
                  <div>
                    <ExcelBtn onClick={exportExcel} loading={loading} />
                  </div>
                }
            />
            <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 my-4 gap-4">
                <Select
                    size="large"
                    className="w-full"
                    allowClear
                    placeholder="Ta'lim shakli bo'yicha filter"
                    optionFilterProp="children"
                    loading={eduFormLoading}
                    onChange={(e) => writeToUrl({name: "edu_form_id", value: e})}
                    options={eduForms?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
                />
                <Select
                  size="large"
                    disabled={!urlValue.filter.edu_form_id}
                    className="w-full"
                    allowClear
                    placeholder="Yo'nalish bo'yicha filter"
                    optionFilterProp="children"
                    loading={directionLoading}
                    onChange={(e) => writeToUrl({name: "direction_id", value: e})}
                    options={directions?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
                />
            </div>
            <Table size="small" columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending} pagination={false} scroll={{x: 1750}} />

            {(data?._meta?.totalCount ?? 0) > 10 ? <CustomPagination totalCount={data?._meta.totalCount} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}

        </div>
    )
}

export default TakenExamStudents;