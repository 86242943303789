import Dashboard from "../pages/dashboard"
import Login from "../pages/login"
import {RoutesTypeElement} from "./types"
import {HiOutlineHome} from 'react-icons/hi'
import EduForms from "../pages/edu-forms"
import Subjects from "../pages/subjects"
import { FaBook } from "react-icons/fa";
import { MdOutlineCastForEducation } from "react-icons/md"
import Directions from "../pages/directions"
import DirectionSubjects from "../pages/direction-subjects"
import EduYears from "../pages/edu-year";
import Test from "../pages/subjects/test";
import UpdateTest from "../pages/subjects/test/crud/update";
import Exams from "../pages/exam";
import ExamUpdate from "../pages/exam/crud/update";
import ExamView from "../pages/exam/crud/view";
import ExamStudentResult from "../pages/exam/components/ExamStudentResult";
import Students from "../pages/students"
import StudentView from "../pages/students/crud/view"
import TakenExamStudents from "../pages/students/takenExamStudents"
import ContractStudents from "../pages/students/contracts"

export const public_routes: RoutesTypeElement[] = [
    {
        name: "Login",
        path: "/signin",
        component: Login,
        config: {
            key: "unlock",
            icon: HiOutlineHome,
            structure: "nonlayout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    }
]

export const protected_routes: RoutesTypeElement[] = [
    {
        name: "Asosiy sahifa",
        path: "/",
        component: Dashboard,
        config: {
            key: "unlock",
            icon: HiOutlineHome,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // Subjects
    {
        name: "Fanlar",
        path: "/subjects",
        component: Subjects,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // test
    {
        name: "Test",
        path: "/subject/:subject_id/tests",
        component: Test,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },
    {
        name: "Test update",
        path: "/subject/:subject_id/tests/create",
        component: UpdateTest,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },
    {
        name: "Test update",
        path: "/subject/:subject_id/tests/update/:test_id",
        component: UpdateTest,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },

    // Directions
    {
        name: "Yo'nalishlar",
        path: "/directions",
        component: Directions,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // direction-subjects
    {
        name: "Yo'nalish fanlari",
        path: "/direction/:direction_id/subjects",
        component: DirectionSubjects,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },

    // Exam
    {
        name: "Imtihonlar",
        path: "/exams",
        component: Exams,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
    {
        name: "Exam",
        path: "/exams/create",
        component: ExamUpdate,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },
    {
        name: "Exam",
        path: "/exams/update/:id",
        component: ExamUpdate,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },
    {
        name: "Exam",
        path: "/exams/view/:id",
        component: ExamView,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },
    {
        name: "Exam student result",
        path: "/exam-student/result/:id",
        component: ExamStudentResult,
        config: {
            key: "unlock",
            icon: FaBook ,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },

    // Students
    {
        name: "Talabalar",
        path: "",
        component: Students,
        config: {
            key: "unlock",
            icon: MdOutlineCastForEducation,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [
            {
                name: "Talabalar",
                path: "/students",
                component: Students,
                config: {
                    key: "unlock",
                    icon: MdOutlineCastForEducation,
                    structure: "layout",
                    exact: true,
                    isShowLink: true,
                },
            },
            {
                name: "Imtihon topshirganlar",
                path: "/students-exam-taken",
                component: TakenExamStudents,
                config: {
                    key: "unlock",
                    icon: MdOutlineCastForEducation,
                    structure: "layout",
                    exact: true,
                    isShowLink: true,
                },
            },
            {
                name: "Shartnomalar",
                path: "/contract",
                component: ContractStudents,
                config: {
                    key: "unlock",
                    icon: MdOutlineCastForEducation,
                    structure: "layout",
                    exact: true,
                    isShowLink: true,
                },
            }
        ],
    },

    // Students
    {
        name: "Talabani korish",
        path: "/students/:student_id",
        component: StudentView,
        config: {
            key: "unlock",
            icon: MdOutlineCastForEducation,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },

    // Edu forms
    {
        name: "Ta'lim shakli",
        path: "/edu-forms",
        component: EduForms,
        config: {
            key: "unlock",
            icon: MdOutlineCastForEducation,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // Edu years
    {
        name: "Ta'lim yili",
        path: "/edu-years",
        component: EduYears,
        config: {
            key: "unlock",
            icon: MdOutlineCastForEducation,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
]

export const protected_routes_for_accountant: RoutesTypeElement[] = [
    {
        name: "Asosiy sahifa",
        path: "/",
        component: Dashboard,
        config: {
            key: "unlock",
            icon: HiOutlineHome,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    {
        name: "Shartnomalar",
        path: "/contract",
        component: ContractStudents,
        config: {
            key: "unlock",
            icon: MdOutlineCastForEducation,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // Students
    {
        name: "Talabalar",
        path: "/students",
        component: Students,
        config: {
            key: "unlock",
            icon: MdOutlineCastForEducation,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
    

    // Students
    {
        name: "Talabani korish",
        path: "/students/:student_id",
        component: StudentView,
        config: {
            key: "unlock",
            icon: MdOutlineCastForEducation,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },
]