import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Button, Empty, Form, Modal, Spin, Switch, message } from 'antd';
import { importExamTestToExcel, updateExamTestStatus } from './crud/request';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import useUrlQueryParams from '../../../cutom-hooks/useUrlQueryParams';
import useGetAllData from '../../../cutom-hooks/useGetAllData';
import { Notification } from '../../../components/Notifications/notification';
import DeleteData from '../../../components/DeleteData';
import { number_order } from '../../../utils/number_orders';
import CustomPagination from '../../../components/Pagination';
import { ITestQuestion } from '../../../models/subject';
import { FILE_URL } from '../../../config/utils';
import { FaEye, FaTrashAlt, FaUpload } from 'react-icons/fa';
import PageHeader from '../../../components/HeaderPage';
import HTMLReactParser from 'html-react-parser';

const Test: React.FC = (): JSX.Element => {
  const [testId, settestId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [test_file, settest_file] = useState<any>();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { subject_id } = useParams()

  const { urlValue } = useUrlQueryParams({
    currentPage: 1,
    perPage: 10,
  });

  const { data, isLoading, refetch } = useGetAllData<ITestQuestion>({
    queryKey: ["questions", subject_id, urlValue],
    urlParams: { "per-page": urlValue.perPage, page: urlValue.currentPage, filter: JSON.stringify({ subject_id: subject_id, type: 2 }) },
    url: `questions?sort=-id&expand=options`,
    options: {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: (!!subject_id),
    },
  });

  const { mutate, isPending: statusLoading } = useMutation({
    mutationFn: (newVals: { idd: number, is_checked: number }) => updateExamTestStatus(newVals.idd, newVals.is_checked),
    onSuccess: async (res) => {
      refetch()
      Notification("success", "update", res?.message)
    },
    onError: (error: AxiosError<any>) => {
      Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
    },
    retry: 0,
  });

  const { mutate: importExamTest } = useMutation({
    mutationFn: ({ file, exam_type_id }: { file: any, exam_type_id?: number }) => importExamTestToExcel(subject_id, file, exam_type_id),
    onSuccess: async (res) => {
      refetch();
      Notification("success", "create", res?.message)
      setIsModalOpen(false)
    },
    onError: (error: AxiosError) => {
      message.error(`${t('Data retrieval error')}`)
    },
    retry: 0,
  });

  const aaa = (ee: string) => {
    return HTMLReactParser(ee)
  }

  return (
    <Spin spinning={isLoading} size="small">
      <PageHeader
        breadCrumbData={[
          { name: "Asosiy panel", path: '/' },
          { name: "Fanlar", path: '/subjects' },
          { name: "Testlar", path: '/subject/:id/tests' }
        ]}
        title={"Fan testlari"}
        isBack={true}
        btn={
          <div>
            {/* <Button onClick={() => setIsModalOpen(true)}>{t('Test import')}</Button> */}
            <Button
              type='primary'
              onClick={() => navigate(`/subject/${subject_id}/tests/create`)}
              className='ml-3'
              size="large"
            >Test qo'shish</Button>
          </div>
        }
      />
      <div className="px-6 pb-5 pt-2 test-page">
        {
          data?.items?.length ? data?.items?.map((item, index) => (
            <div key={item?.id} className='bg-zinc-50 p-3 rounded-md mb-5 hover:shadow-lg relative test-list-actions-wrapper'>
              <div className='d-f absolute top-3 right-3 p-1 bg-zinc-50 test-list-actions'>
                <Switch className='mt-1' checked={item?.is_checked == 1} onChange={(e) => { mutate({ idd: item?.id, is_checked: e ? 1 : 0 }); settestId(item?.id) }} loading={testId == item?.id && statusLoading} defaultChecked />
                <DeleteData
                  refetch={refetch}
                  url={"questions"}
                  id={item?.id}
                >
                  <Button type="primary" className="mx-2" danger ghost> 
                    <FaTrashAlt className="text-red-500" />
                  </Button>
                </DeleteData>
                <Button onClick={() => navigate(`/subject/${subject_id}/tests/update/${item?.id}`)} ><FaEye className="view" /></Button>
              </div>
              <p className='flex items-center'>
                <Avatar size={35} style={{ color: '#000', backgroundColor: '#eeeeee' }} className='mr-2'>{number_order(urlValue.currentPage, urlValue.perPage, Number(index), isLoading)}</Avatar>
                {/* {index > 1 ? <div dangerouslySetInnerHTML={{ __html: item?.text ? item?.text : "" }} /> : ""}
                {item?.text} */}
                {/* <p ref={questionRef} dangerouslySetInnerHTML={{ __html: item?.text ? item?.text : ""}} /> */}
                {/* {parse(item?.text ? item?.text : "", {})} */}
                {item?.text ? aaa(item?.text) : ""}
              </p>
              {item?.file ? <img width={200} className='ml-[50px] mt-4' src={FILE_URL + item?.file} alt="" /> : ""}
              <div className='pl-5 pt-4'>
                {
                  item?.options?.map(option => (
                    <div key={option?.id} className={`p-3 rounded-md mb-3 ${option?.is_correct === 1 ? "bg-green-100" : "bg-[#f0f0f0]"} `}>
                      <p dangerouslySetInnerHTML={{ __html: option?.text ?? "" }} />
                      {option?.file ? <img width={120} className='mt-2' src={FILE_URL + option?.file} alt="" /> : ""}
                    </div>
                  ))
                }
              </div>
            </div>
          )) : <Empty />
        }
        {(data?._meta?.totalCount ?? 0) > 10 ? (
          <CustomPagination
            totalCount={data?._meta.totalCount}
            currentPage={urlValue.currentPage}
            perPage={urlValue.perPage}
          />
        ) : null}

      </div>
      <Modal title={t("Exam test")} open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)} footer={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(vals: any) => importExamTest({ exam_type_id: vals?.exam_type_id, file: test_file })}
          className='my-4'
          size='large'
        >
          {/* <Form.Item
            name={`exam_type_id`}
            shouldUpdate
            rules={[{ required: true, message: `Please input exam type!` }]}
            className="w-[100%] mb-0 mr-3"
            label={t('Exam type')}
          >
            <Select
              loading={isFetching}
              placeholder={t(`Select exam types`) + " ..."}
              allowClear
              showSearch
              filterOption={cf_filterOption}
              className='w-[100%] mb-4'
            >
              {
                examTypes?.items?.map((item: any, i: number) => (
                  <Select.Option key={i} value={item?.id} >{item?.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item> */}
          <Form.Item
            label={t("Document file")}
            name='passport_file'
            className='w-[100%] mb-4'
            rules={[{ required: false, message: `Please upload file` }]}
          >
            <>
              <input type="file" accept=".xls,.xlsx" onChange={(e: any) => { settest_file(e.target.files[0]); console.log(e.target.files[0]?.name) }} className="hidden" style={{ display: "none" }} id="excel_import" />
              <label htmlFor="excel_import" className="d-f cursor-pointer text-[#52C41A] rounded-lg border border-solid border-[#52C41A] px-3 py-1" >
                <FaUpload fontSize={16} color="#52C41A" />&nbsp;&nbsp;Import excel
              </label>
              {test_file?.name}
            </>
          </Form.Item>
          <div className="flex justify-end">
            <Button htmlType='submit' loading={statusLoading}>{t("Save")}</Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

export default Test;


// test_create
// test_update
// test_index
// test_delete
// test_view