import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../Breadcrumb";
import { HiArrowNarrowLeft } from "react-icons/hi";

type THeaderProps = {
  title: string,
  btn?: React.ReactNode,
  breadCrumbData?: Array<{
    name: string,
    path: string
  }>,
  isBack?: boolean | string,
  backUrl?: string;
  menuType?: "menu" | 'tab',
  className?: string,
}

const PageHeader: FC<THeaderProps> = ({ title, btn, breadCrumbData, isBack = false, menuType = "menu", className, backUrl }): JSX.Element => {
  const navigate = useNavigate()

  const initialBreadCrumbData = [
    {
      name: "Home",
      path: "/"
    },
    {
      name: title,
      path: "/"
    },
  ]

  return (
    <div className={`${menuType == "menu" ? "pb-[10px]" : "pb-[8px]"} ${className ?? ""}`} style={{ borderBottom: menuType == "menu" ? "1px solid #F0F0F0" : "", background: "var(--v-bg)" }}>
      <CustomBreadcrumb arr={breadCrumbData ?? initialBreadCrumbData} />
      <div className="flex justify-between items-center mt-[14px]">
        <div className="flex items-center">
          {
            isBack ? <HiArrowNarrowLeft onClick={() => backUrl ? navigate(backUrl) : navigate(-1)} className="mr-[10px] text-[25px] cursor-pointer" /> : ""
          }
          <p className="text-[24px] font-bold m-0">{title}</p>
        </div>
        {btn}
      </div>
    </div>
  )
}

export default PageHeader