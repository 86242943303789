import React, { useState, useEffect } from 'react';
import { Divider, Spin } from "antd";
import PageHeader from '../../../components/HeaderPage';
import { FILE_URL } from '../../../config/utils';
import useUrlQueryParams from '../../../cutom-hooks/useUrlQueryParams';
import useGetOneData from '../../../cutom-hooks/useGetOneData';
import { useParams } from 'react-router-dom';

const ExamStudentResult: React.FC = (): JSX.Element => {

  const { urlValue, writeToUrl } = useUrlQueryParams({});
  const [tests, setTests] = useState<any[]>([]);
  const {id} = useParams();

  const { data, isLoading } = useGetOneData<any>({
    queryKey: ["exam-students/get", id],
    url: `exam-students/get/${id}?expand=examSubject.subject,student,examSubject.question.question.options`,
    urlParams: { "per-page": 0, filter: JSON.stringify({ exam_id: id }) },
    options: {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!id
    }
  });

  useEffect(() => {
    if(data?.data?.examSubject?.length && !tests?.length){
      for (const item of data?.data?.examSubject) {
        setTests((p: any) => ([...p, ...item?.question]))
      }
    }

    if (!urlValue.filter_like?.test) {
      writeToUrl({ name: "test", value: 1 });
    }
  }, [data]);

  return (
    <div className="">
      <PageHeader title={ data?.data?.student ? data?.data?.student?.last_name + " " + data?.data?.student?.first_name : `Talaba test natijalari`} isBack
        breadCrumbData={[
          { name: "Asosiy sahifa", path: "/" },
          { name: "Imtihonlar", path: "/exams" },
          { name: "Talabalar", path: 'Talaba test natijalari' }
        ]}
      />
      <Spin spinning={isLoading} >

        <div className="flex max-md:flex-col gap-6">
          <div className="w-full mx-auto max-h-[83vh] overflow-x-auto scroll-style">
            {
              data?.data?.examSubject?.map((subject: any, sindex: number) => (
                <div key={sindex}>
                    <h3 className='my-4'>{subject?.subject?.name}</h3>
                      {
                        subject?.question?.map((e: any, i: number) => (
                          <div id={e?.id} className="min-h-[20px] bg-[#F7F7F7] rounded-lg border border-solid border-gray-200 md:p-4 p-2 max-md:pb-0 mb-4" key={i}>
                            <div>
                              <div className="flex max-md:flex-col gap-3">
                                <h5>{i + 1}.</h5>
                                <div className='w-1/2 max-md:w-full' dangerouslySetInnerHTML={{ __html: e?.question?.text ?? "" }} />
                                {/* <p>{i}<span dangerouslySetInnerHTML={{ __html: e?.question?.text ?? "" }}></span></p>
                                <p>{i}<span dangerouslySetInnerHTML={{ __html: e?.question?.text ?? "" }}></span></p> */}
                                {e?.question?.file ? <>
                                  <img src={FILE_URL + e?.question?.file} alt='image' className='w-1/2 max-md:w-full rounded-lg' />
                                </> : null}
                              </div>
                              <Divider className='md:-ml-4 -ml-2 md:w-[calc(100%+32px)] w-[calc(100%+16px)] max-md:my-2' />
                              <div className="variants flex flex-col gap-4 my-4">
                                {
                                  e?.question?.options?.map((v: any, idx: number) => (
                                    <div key={idx} className="flex gap-2" >
                                      {/* <div className={`h-[44px] w-[44px] flex-center rounded-lg border border-solid p-3 ${e?.question?.student_option == v.id ? "bg-[#FCE8E6] text-[#C5221F] border-[#ffb3ac]" : "bg-[#fff] border-gray-200"}`}>{idx == 0 ? "A" : idx === 1 ? "B" : idx === 2 ? "C" : idx === 3 ? "D" : "E"}</div> */}
                                      <div className={`w-full flex max-md:flex-col gap-3 rounded-lg border border-solid p-3 ${e?.student_option == v.id ? e?.is_correct ? "bg-[#dff3e5] text-[#399b5a] border-[#a2d8b2]" : "bg-[#FCE8E6] text-[#C5221F] border-[#ffb3ac]" : "bg-[#fff] border-gray-200"}`}>
                                        <div className='w-3/5 max-md:w-full' dangerouslySetInnerHTML={{ __html: v.text ?? "" }} />
                                        {v.file ? <>
                                            <img src={FILE_URL + v.file} alt='image' className='w-2/5 max-md:w-full rounded-lg' />
                                          </> : null}
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        ))
                      }
                </div>
              ))
            }
          </div>
          <div className="w-96 max-md:w-full h-max md:min-h-[250px] flex flex-col justify-between gap-4 sticky top-5 bg-[#F7F7F7] rounded-lg border border-solid border-gray-200 shadow-md- p-3 my-6">
            <div>
              <div className="flex flex-wrap gap-2 mt-4">
                {
                  tests?.map((e, i) => (
                    <a href={`#${e?.id}`} key={i} onClick={() => { writeToUrl({ name: "test", value: i + 1 }) }} className={`md:h-[36px] h-[30px] md:w-[36px] w-[30px] flex justify-center items-center md:rounded-lg rounded border border-solid cursor-pointer ${Number(urlValue.filter_like?.test) === i + 1 ? (e?.student_option ? e?.is_correct ? "bg-[#dff3e5] text-[#399b5a]" : "bg-[#FCE8E6] text-[#C5221F]" : "text-[#005C47] bg-white") : e?.student_option ? e?.is_correct ? "bg-[#dff3e5] text-[#399b5a] border-[#a2d8b2]" : "bg-[#FCE8E6] text-[#C5221F] border-[#ffb3ac]" : "bg-white border-gray-200"}`}>{i + 1}</a>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ExamStudentResult;