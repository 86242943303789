import {Route, Routes, Navigate} from 'react-router-dom'
import { protected_routes, protected_routes_for_accountant, public_routes } from "./routes";
import { RoutesTypeElement } from "./types";
import { useAppSelector } from "../store/service";
import AdminLayout from '../components/Dashboard/Layout';

function RenderComponent(MyComponent: RoutesTypeElement | Omit<RoutesTypeElement, 'submenu'>) {


    const Component = MyComponent.component;

    if (MyComponent.config.structure === 'layout') {

        return (
            <AdminLayout>
                <Component />
            </AdminLayout>
        )
    }

    return <Component />

}

const RoutesMiddleware = () => {

    const render_route = (element: RoutesTypeElement | Omit<RoutesTypeElement, 'submenu'>) => {
        return <Route key={element.config.key} path={element.path} element={RenderComponent(element)} />
    }
    
    const auth = useAppSelector(state => state.auth);
        
    if (auth.isAuthenticated) {
        return (
            <Routes>
                {
                    auth?.role === 'accountant' ? 
                            (
                                protected_routes_for_accountant.length && protected_routes_for_accountant.map((element) => {

                                    if (element.submenu.length) {
        
                                        return element.submenu.map((childElement) => render_route(childElement))
                                    }
        
                                    return render_route(element)
        
                                })
                            )
                        : (
                            protected_routes.length && protected_routes.map((element) => {

                                if (element.submenu.length) {
        
                                    return element.submenu.map((childElement) => render_route(childElement))
                                }
        
                                return render_route(element)
        
                            })
        
                            )
                }
                {
                    protected_routes.length && protected_routes.map((element) => {

                        if (element.submenu.length) {

                            return element.submenu.map((childElement) => render_route(childElement))
                        }

                        return render_route(element)

                    })
                }
                <Route path="/signin" element={<Navigate to="/" />} />
            </Routes>
        )
    }


    return (
        <Routes>
            {
                public_routes?.length && public_routes?.map((element) => {

                    if (element.submenu.length) {
                        return element.submenu.map((childElement) => render_route(childElement))
                    }

                    return render_route(element)
                })
            }
            <Route path="*" element={<Navigate to={localStorage.getItem("access_token") ? window.location.pathname : "/signin"} replace />} />

        </Routes>
    )




}



export default RoutesMiddleware;