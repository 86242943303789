import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { BASE_URL } from '../utils';
import { message } from 'antd';
import { ResponseError } from './errors';

let instance = axios.create();

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const access_token: string = localStorage.getItem('access_token') ?? '';
    const url_lang = localStorage.getItem('i18lang') || 'uz';

    config.baseURL = `${BASE_URL}${url_lang}`;

    if (!config.url?.includes('auth/login')) {
        config.headers.Authorization = `Bearer ${access_token}`;
    }
    config.headers!.Accept = 'application/json';
    // config.headers!['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers!['Access-Control-Allow-Origin'] = '*';
    config.headers!['Access-Control-Allow-Credentials'] = true;
    return config;
}

const onRequestError = async (error: AxiosError): Promise<AxiosError> => {
    new ResponseError(error);
    return Promise.reject(error);
}
const onResponse = (response: AxiosResponse): AxiosResponse => {


    switch (response.status) {
        case 201:            
            // message.success("Ma'lumot qo'shildi!");
            break;
        case 200:
            if (response.config.method?.toUpperCase().includes('PUT')) {
                message.success("Ma'lumot o'zgartirildi!");
            } else if (response.config.method?.toUpperCase().includes('POST')) {
                if(response.config.url !== "/auth/login"){
                //   message.success("Ma'lumot qo'shildi!");
                }
            }
            break;
        case 204:
            message.success("Ma'lumot o'chirildi!");
            break;
    }
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {

    new ResponseError(error);
    return Promise.reject(error);
}

instance.interceptors.request.use(onRequest, onRequestError)
instance.interceptors.response.use(onResponse, onResponseError)



export default instance;