import { Button, Modal, Select, message } from "antd"
import { Dispatch, useState } from "react"
import { FILE_URL } from "../../../config/utils"
import { useMutation } from "@tanstack/react-query";
import { Notification } from "../../../components/Notifications/notification";
import { AxiosError } from "axios";
import { checkSertificate } from "../crud/requests";


const GiveBallModal = ({isModalOpen, setIsModalOpen, examStudent, setexamStudent, refetch}: {isModalOpen: boolean, setIsModalOpen: Dispatch<boolean>, examStudent: any, setexamStudent: Dispatch<any>, refetch: any}) => {

    const [subject_id, setsubject_id] = useState()

    const { mutate } = useMutation({
        mutationFn: () => checkSertificate(subject_id),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message)
          setsubject_id(undefined)
          refetch()
          setIsModalOpen(false)
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
      });

    return (
        <Modal 
            width={1500} 
            title={"Sertifikatni tasdiqlash"}
            footer={[
                <Button type="primary" onClick={() => {setIsModalOpen(false); setexamStudent(undefined); setsubject_id(undefined)}}>Yopish</Button>
            ]}
            open={isModalOpen} 
            onCancel={() => setIsModalOpen(false)}
        >
            <iframe className="max-w-full min-w-[50%] rounded-lg" src={FILE_URL + examStudent?.student?.c_file} width="100%" height={900} ></iframe>
            <div className="flex items-center mt-8">
                <Select
                    placeholder="Fanni tanlang"
                    optionFilterProp="children"
                    className="mr-3 w-[200px]"
                    onChange={(e) => setsubject_id(e)}
                    options={examStudent?.examSubject?.map((i: any) => ({value: i?.id, label: i?.subject?.name}))}
                />
                <Button onClick={() => subject_id ? mutate() : message.warning("Tasdiqlash uchun fanni tanlang!")} type="primary">Tasdiqlash</Button>
            </div>
        </Modal>
    )
}
export default GiveBallModal;