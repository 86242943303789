import React, { useEffect, useState } from 'react';
import { Button, Row, Select, Switch, Table, Tag } from "antd";
import { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import useUrlQueryParams from '../../cutom-hooks/useUrlQueryParams';
import useGetAllData from '../../cutom-hooks/useGetAllData';
import { Notification } from '../../components/Notifications/notification';
import { number_order } from '../../utils/number_orders';
import Actions from '../../components/Actions';
import PageHeader from '../../components/HeaderPage';
import CustomPagination from '../../components/Pagination';
import { finalExamStatusCheck } from './crud/requests';
import { useTranslation } from 'react-i18next';

const dateParserToDatePicker = (second: number | undefined) => dayjs((new Date(Number(second)*1000))).format('DD-MM-YYYY HH:mm')


const Exams: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const [allData, setAllData] = useState<any[]>([]);
  const navigate = useNavigate()
  const { urlValue, writeToUrl } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

  const { data, refetch, isLoading } = useGetAllData<any>({
    queryKey: ["exams", urlValue],
    url: "exams?sort=-id&expand=subject,direction.eduForm,language,eduYear",
    urlParams: { "per-page": urlValue.perPage, page: urlValue.currentPage, filter: JSON.stringify(urlValue?.filter) },
    options: {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (res: any) => {
        setAllData(res?.items);
      }
    }
  });


  const { data: languages } = useGetAllData({
    queryKey: ["languages"],
    url: "languages",
  })

  const { data: eduYears } = useGetAllData({
    queryKey: ["edu-years"],
    url: "edu-years",
  })

  const { data: directions } = useGetAllData({
    queryKey: ["directions", urlValue.filter?.language_id],
    url: "directions",
    urlParams:{
        expand: "eduForm",
        page: urlValue.currentPage,
        'per-page': urlValue.perPage,
        filter: JSON.stringify({language_id: urlValue.filter?.language_id})
    }
  })

  const { mutate } = useMutation({
    mutationFn: (newVals: {status: number, id: number, order: 1 | 2 | 3 | 4 | 5}) => finalExamStatusCheck(newVals?.id, newVals?.status, newVals.order),
    onSuccess: async (res) => {
      Notification("success", "update", res?.message)
      refetch()
    },
    onError: (error: AxiosError<any>) => {
      Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
    },
    retry: 0,
  });

  const columns: ColumnsType<any> = React.useMemo(() => [
    {
      title: '№',
      dataIndex: 'order',
      render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isLoading),
      width: 45,
    },
    {
      title: t('Direction'),
      dataIndex: 'direction',
      render: (direction,_e) => direction?.name
    },
    {
      title: t("Ta'lim tili"),
      dataIndex: 'language',
      render: (language,_e) => language?.name
    },
    {
      title: t("Ta'lim shakli"),
      dataIndex: 'direction',
      render: (direction,_e) => direction?.eduForm?.name
    },
    {
      title: t('Date'),
      width: 300,
      render: (e) => <span>{dateParserToDatePicker(e?.start_time)} - {dateParserToDatePicker(e?.finish_time)}</span>
    },
    {
      title: <span>{t('Duration')} (minut)</span>,
      dataIndex: "duration_time",
      render: (e) => <span>{e}</span>,
    },
    {
      title: t('Edu year'),
      dataIndex: "eduYear",
      render: (e) => <span>{e?.name}</span>,
    },
    // {
    //   title: t('Max ball'),
    //   dataIndex: 'max_ball'
    // },
    {
      title: t("Actions"),
      dataIndex: 'actions',
      width: 120,
      align: "center",
      render: (_i, e) => <Actions
        id={e?.id}
        url={'exams'}
        refetch={refetch}
        onClickEdit={() => navigate(`/exams/update/${e?.id}`)}
        onClickView={() => navigate(`/exams/view/${e?.id}`)}
      />,
    },
  ], [data?.items, urlValue]);

  return (
    <div className="">
      <PageHeader title={`Imtihonlar`}
        breadCrumbData={[
          { name: "Asosiy sahifa", path: '/' },
          { name: "Imtihonlar", path: 'exams' }
        ]}
        btn={
          <div className='flex'>
            <Button size="large" type='primary' onClick={() => { navigate("/exams/create") }} >Qo'shish</Button>
          </div>
        }
      />
      <div className="grid grid-cols-3 my-4 gap-4">
        <Select
            size="large"
            className="w-full"
            allowClear
            placeholder="Ta'lim tili bo'yicha filter"
            optionFilterProp="children"
            value={urlValue.filter?.language_id}
            onChange={(e) => {
              writeToUrl({name: "language_id", value: e});
              writeToUrl({name: "direction_id", value: undefined})
            }}
            options={languages?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
        />
        <Select
            size="large"
            className="w-full"
            allowClear
            placeholder="Ta'lim yo'nalishi"
            optionFilterProp="children"
            value={urlValue.filter?.direction_id}
            disabled={!urlValue.filter?.language_id}
            onChange={(e) => writeToUrl({name: "direction_id", value: e})}
            options={directions?.items?.map((item: any) => ({label: `${item?.name} - ${item?.eduForm?.name}`,  value: item?.id}))}
        />
        <Select
            size="large"
            className="w-full"
            allowClear
            placeholder="Ta'lim yili bo'yicha filter"
            optionFilterProp="children"
            value={urlValue.filter?.language_id}
            onChange={(e) => writeToUrl({name: "edu_year_id", value: e})}
            options={eduYears?.items?.map((item: any) => ({label: item?.name, value: item?.id}))}
        />
    </div>
      <div className="">
        <Table
          columns={columns}
          dataSource={data?.items.length ? data?.items : allData}
          pagination={false}
          loading={isLoading}
          size="middle"
          className="mt-3"
          rowClassName="py-[12px]"
        />
        {(data?._meta?.totalCount ?? 0) > 10 ? <CustomPagination totalCount={data?._meta.totalCount} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}
      </div>
    </div>
  );
};

export default Exams;