import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Table, Tooltip } from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import useGetAllData from '../../../cutom-hooks/useGetAllData';
import { ColumnsType } from 'antd/es/table';
import { HiEye } from 'react-icons/hi';
import DeleteData from '../../../components/DeleteData';
import { MdDelete } from 'react-icons/md';
import GiveBallModal from './ballGiveModal';

const ExamStudents: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [file, setfile] = useState<string>("");
  const [examStudent, setexamStudent] = useState<any>();


  const { data, refetch, isLoading } = useGetAllData<any>({
    queryKey: ["exam-students", id],
    url: "exam-students?sort=-id&expand=examSubject.subject,student",
    urlParams: { "per-page": 0, filter: JSON.stringify({ exam_id: id }) },
    options: {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!id
    }
  });

  const columns: ColumnsType<any> = React.useMemo(() => [
    {
      title: '№',
      dataIndex: 'order',
      render: (_, __, i) => i + 1,
      width: 45,
    },
    {
      title: t('F.I.O'),
      dataIndex: 'student',
      render: (e) => <span>{e?.last_name} {e?.first_name} {e?.middle_name}</span>
    },
    {
      title: t('Sertifikatni tasdiqlash'),
      width: 300,
      render: (e) => e?.student?.certificate_type === 1 ? <svg onClick={() => {setIsModalOpen(true); setfile(e?.student?.c_file); setexamStudent(e)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
        </svg> : null
    },
    {
      title: "Fanlardan ballari",
      dataIndex: "examSubject",
      render: (e) => <div>{e?.map((a: any, i: number) => (
        <p key={i} ><span className="opacity-60">{a?.subject?.name}:</span> <>{a?.ball}</></p>
      ))}</div>,
    },
    {
      title: t('Ball'),
      dataIndex: 'ball'
    },
    {
      title: t("Actions"),
      dataIndex: 'actions',
      width: 120,
      align: "center",
      render: (_i, e) => <div>
            <Tooltip placement="topLeft" className='mr-4' title={"View"}><HiEye className="view cursor-pointer text-[17px]" onClick={() => navigate(`/exam-student/result/${e?.id}`)} /></Tooltip>
            <Tooltip placement="left" title={"Delete"}>
              <DeleteData refetch={refetch} url={'exam-students'} id={e?.id}><MdDelete className="delete cursor-pointer text-[17px]" /></DeleteData>
            </Tooltip>
          </div>,
    },
  ], [data?.items]);


  return (
    <div className="">
      <GiveBallModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setexamStudent={setexamStudent} examStudent={examStudent} refetch={refetch} />
      <Table
        columns={columns}
        dataSource={data?.items}
        pagination={false}
        loading={isLoading}
        size="middle"
        className="mt-3"
        rowClassName="py-[12px]"
      // scroll={{x: 2000}}
      />
    </div>
  );
};

export default ExamStudents;


/**
  * _index
  * _delete
  * _update
  * _view
*/