import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/style.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BrowserRouter } from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { Provider } from 'react-redux';
import store from './store';
import { ConfigProvider } from 'antd';
import { antdCustomTokens } from './static_data/antd_tokens';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/utils/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ConfigProvider theme={antdCustomTokens()} >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    </I18nextProvider>
  </QueryClientProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
