import { Dispatch, useEffect } from 'react'
import { Button, Drawer, Form, Input } from "antd";
import { useMutation } from '@tanstack/react-query';
import { Notification } from '../../../components/Notifications/notification';
import { AxiosError } from 'axios';
import { submitData } from './request';
import MultipleInput from '../../../components/MultipleInput';

const UpdateEduYear = ({ open, setOpen, refetch, selectedItem, setselectedItem }: { open: boolean, setOpen: Dispatch<boolean>, refetch: any, selectedItem: any, setselectedItem: Dispatch<any> }) => {

    const [form] = Form.useForm();

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        form.setFieldsValue({
            name: selectedItem?.name,
        })
    }, [selectedItem])

    useEffect(() => {
        if (!open) setselectedItem(undefined)
    }, [open])

    const { mutate, isPending } = useMutation({
        mutationFn: (newVals) => submitData(selectedItem?.id, newVals),
        onSuccess: async (res) => {
            Notification("success", "update", res?.message)
            refetch()
            setselectedItem(undefined)
            setOpen(false)
        },
        onError: (error: AxiosError<any>) => {
            Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    return (
        <div>
            <Drawer key={3} title="Ta'lim yili" placement="right" width={500} onClose={onClose} open={open}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={mutate}
                    autoComplete="off"
                    size='large'
                >
                    {
                        selectedItem?.id ?
                            <Form.Item
                                label="Nomi"
                                name="name"
                                rules={[{ required: true, message: 'Please input name!' }]}
                            >
                                <Input placeholder="Nomi" />
                            </Form.Item>
                            : <MultipleInput layout='vertical' inputSpan={24} textAreaSpan={24} textAreaRows={3} isDescription={false} />
                    }
                    <Form.Item className='flex justify-end'>
                        <Button type="primary" htmlType="submit" loading={isPending}>
                            Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}
export default UpdateEduYear;