
import { Button, Form, Input } from 'antd';
import Logo from '../../assets/images/favicon.svg'
import { useAppDispatch } from '../../store/service';
import { signIn } from '../../store/auth';
import './style.scss';

const Login = () => {
    
    const [form] = Form.useForm();
    const dispatch: any = useAppDispatch();

    const onFinish = (values: {username: string, password: string}) => {
        dispatch(signIn(values))
    }

    return (
        <div className="bg-[#17406C] relative">
            <div className="login-wrapper">
                <div className="login-card e-card-shadow relative z-[2]" >
                    <div className='md:px-2 pt-10 text-center bg-[#ffffff] rounded-l-[1rem] my-0 text-white w-[60%] login_title_card' style={{border: "1px solid #1A386A"}} >
                        <img src={Logo} className='lg:w-[200px] w-[150px]' alt="" />
                        <p className='text-[22px] mx-5 my-4 text-[#1A386A] font-medium'>Tashkent Perfect University qabul platformasi</p>
                        <p className='text-[22px] mx-5 text-[#1A386A] font-medium'>Admission platform of Tashkent Perfect University</p>
                    </div>
                    <div className="login-card2 rounded-r-[1rem]" style={{border: "1px solid #1A386A"}}>
                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            className='w-[100%]'
                            onFinish={onFinish}
                            size='large'
                        >
                            <div className='mobile-login-logo'>
                                <img src={Logo} className='w-[150px] inline-block mx-auto' alt="" />
                                <p className='my-8 text-[18px]'>Tashkent Perfect University<br /> qabul platformasi</p>
                            </div>
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Button type="primary" className='w-[100%]' htmlType='submit'>Submit</Button>
                        </Form>
                    </div>
                </div>

                <div>
                    <div className="left-fixed-elemet top-[70%]" style={{zIndex: "1"}}>
                        <div className="first-box"></div>
                        <div className="second-box"></div>
                        <div className="third-box"></div>
                    </div>

                    <div className="right-fixed-elemet flex flex-col justify-end top-[20%]" style={{zIndex: "1"}}>
                        <div className="first-box ml-auto"></div>
                        <div className="second-box ml-auto"></div>
                        <div className="third-box ml-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;