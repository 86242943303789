

// generate antd Col span
export const generateAntdColSpan = (span: number | { xs?: number; sm?: number; md?: number; lg?: number; xl?: number; xxl?: number } | undefined) => {
    if (typeof span === "number") return { span };

    let initialSpan: any = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 };
    if (span) {
      Object.entries(span)?.forEach(([key, value]) => {
        initialSpan = { ...initialSpan, [key]: value };
      });
    }
    return initialSpan;
};

//  amtd select search
export const cf_filterOption = (input: string, option: any) => {
  return (
    String(option?.children)
      .toLowerCase()
      .indexOf(String(input).toLowerCase()) >= 0
  );
};