/** @format */

import { useQuery } from "@tanstack/react-query";
import { InformerDRDT } from "../models";
import { CLIENT_API } from "../services/client.request";
import i18next from "i18next";

const useGetAllData = <T = any>({
  queryKey,
  url,
  options,
  urlParams,
}: {
  queryKey: Array<string | number | undefined | any>;
  url: string;
  options?: any;
  // options?: UseQueryOptions<InformerDRDT<T>>;
  urlParams?: Record<string | number, any>;
}) => {
  const response = useQuery<InformerDRDT<T>>({
    queryKey: [...queryKey, i18next.language],
    queryFn: () => CLIENT_API.getAll({ url, _params: urlParams }),
    refetchOnWindowFocus: false,
    retry: 0,
    ...options,
  });

  return { ...response };
};

export default useGetAllData;