import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { filterLinksForSidebar } from './utils';
import Logo from '../../assets/images/favicon.svg';
import useWindowSize from '../../cutom-hooks/useWindowSize';

const { Sider } = Layout;

const CSidebar: React.FC = () => {

    const [collapsed, setCollapsed] = useState(false);
    const {width} = useWindowSize();
    const pathname = window.location.pathname;

    useEffect(() => {        
        if(width < 992){
            setCollapsed(true)
        }else {
            setCollapsed(false)
        }
    }, [width])

    return (
        <>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="demo-logo-vertical" />
                <div className='text-center mb-5'>
                    <img src={Logo} className={`transition ${collapsed ? "w-[50px] mt-4" : "w-[100px] mt-4"}`} alt="perfect university logo" />
                    {/* {
                        collapsed ?
                        <h1 className='text-white mt-4'>ET</h1>
                        :<img src={Logo} className='w-[100px] mt-4' alt="perfect university logo" />
                    } */}
                </div>
                <Menu theme="dark" defaultSelectedKeys={[pathname]} activeKey={pathname} mode="inline" items={filterLinksForSidebar()} />
            </Sider>
        </>
    );
};

export default CSidebar;