import {useState} from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import UpdateData from "./crud/update";
import { number_order } from "../../utils/number_orders";
import useUrlQueryParams from "../../cutom-hooks/useUrlQueryParams";
import CustomPagination from "../../components/Pagination";
import VieWData from "./crud/view";
import { useTranslation } from "react-i18next";

const EduForms = () => {

    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [openView, setOpenView] = useState<boolean>(false);
    const {t} = useTranslation();

  const [selectedItem, setselectedItem] = useState<any>();
  const { urlValue } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

    const columns: ColumnsType<any> = [
        {
            title: '№',
            dataIndex: 'order',
            render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), isPending),
            width: 45,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => <Actions
                id={record?.id}
                url={'edu-forms'}
                refetch={refetch}
                onClickEdit={() => {setOpenUpdate(true); setselectedItem(record)}}
                onClickView={() => {setOpenView(true); setselectedItem(record)}}
            />,
        },
    ];
      

    const { data, refetch, isPending } = useGetAllData({
        queryKey: ["edu-forms", urlValue],
        url: "edu-forms",
        urlParams:{
            expand: "createdBy,updatedBy"
        }

    })    
      
    return(
        <div>
            <PageHeader
                breadCrumbData={[
                { name: "Asosiy panel", path: '/' },
                { name: "Ta'lim shakli", path: '/edu-forms' }
                ]}
                title={"Ta'lim shakli"}
                btn={
                    <Button size="large" onClick={() => {setOpenUpdate(true); setselectedItem(undefined)}} type="primary">Qo'shish</Button>
                }
            />
            <Table columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending} />
            
            {(data?._meta?.totalCount ?? 0) > 10 ? <CustomPagination totalCount={data?._meta.totalCount} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}

            <VieWData open={openView} setOpen={setOpenView} selectedItem={selectedItem} setselectedItem={setselectedItem} />
            <UpdateData open={openUpdate} setOpen={setOpenUpdate} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
        </div>
    )
}

export default EduForms;